<script>
  import Button, { Label } from "@smui/button";
  import { getContext, onDestroy, tick } from "svelte";

  import FinishWorkDialog from "~/components/FinishWorkDialog.svelte";
  import { CONTEXT_KEY_USER, QrHomeTypes } from "~/libs/constants";
  import pageRouter from "~/libs/pageRouter";
  import { lastOperationType } from "~/libs/stores";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {import("~/libs/constants").LastOperationTypes} */
  let messageType;

  const lastOperationTypeUnsubscriber = lastOperationType.subscribe(
    async (value) => {
      if (value !== undefined) {
        await tick();
        messageType = value;
        lastOperationType.set(undefined);
        finishWorkDialog.openDialog();
      }
    },
  );

  /**
   * ダイアログコンポーネント（業務終了登録用）のインスタンス
   * @type {FinishWorkDialog}
   */
  let finishWorkDialog;

  onDestroy(() => {
    lastOperationTypeUnsubscriber();
  });
</script>

<div class="emptyListContent">
  <span class="material-icons icon">playlist_add_check</span>
  <p class="emptyText">配達が必要な荷物はありません</p>
  <button
    class={userContext.readOnly ? "moveButton-inactive" : "moveButton"}
    on:click={() => {
      pageRouter.moveToQrHome(QrHomeTypes.OUT_FOR_DELIVERY);
    }}
    disabled={userContext.readOnly}
  >
    配送センターから荷物を持ち出す
  </button>
  <Button
    color="secondary"
    ripple={false}
    on:click={finishWorkDialog.openDialog}
  >
    <Label>宅配業務を終了する</Label>
  </Button>
</div>

<!-- 業務終了登録ダイアログ -->
<FinishWorkDialog bind:this={finishWorkDialog} bind:messageType />

<style lang="scss">
  .emptyListContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .icon {
      font-size: 200px;
      color: #d9d9d9;
    }
    .emptyText {
      margin: -10px 0 45px;
    }
    .moveButton {
      margin-bottom: 30px;
      padding: 14px 30px;
      color: #fff;
      background-color: #018786;
      border: none;
      border-radius: 10px;
      font-size: 17px;
    }

    .moveButton-inactive {
      margin-bottom: 30px;
      padding: 14px 30px;
      color: #fff;
      background-color: #b2b2b2;
      border: none;
      border-radius: 10px;
      font-size: 17px;
    }
  }
</style>
