import { datadogLogs } from "@datadog/browser-logs";

const logger = {
  /**
   * エラーログを記録します。
   * @param {string} message
   * @param {object} [messageContext]
   * @param {Error} [error]
   */
  async error(message, messageContext, error) {
    messageContext.mobileContext = await this.getMobileContext();
    console.error(message, messageContext, error);

    // Datadogを使用しない環境用の書き換え処理
    _REMOVABLE_UNUSED_DATADOG_: {
      try {
        datadogLogs.logger.warn(message, messageContext, error);
      } catch (error) {
        // おそらくDatadogのloggerで例外がスローされることはないと思うが、念のためcatch
        console.error(error);
      }
      break _REMOVABLE_UNUSED_DATADOG_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
    }
  },

  /**
   * インフォログを記録します。
   * @param {string} message
   * @param {object} [messageContext]
   */
  async info(message, messageContext) {
    messageContext.mobileContext = await this.getMobileContext();
    console.info(message, messageContext);
    // Datadogを使用しない環境用の書き換え処理
    _REMOVABLE_UNUSED_DATADOG_: {
      try {
        messageContext.mobileContext = await this.getMobileContext();
        datadogLogs.logger.info(message, messageContext);
      } catch (error) {
        // おそらくDatadogのloggerで例外がスローされることはないと思うが、念のためcatch
        console.error(error);
      }
      break _REMOVABLE_UNUSED_DATADOG_; // 未使用ラベルがViteの事前処理で削除されてesbuildに渡せない対策
    }
  },

  async getMobileContext() {
    let mobileContext;
    if ("userAgentData" in navigator) {
      mobileContext = await navigator.userAgentData.getHighEntropyValues([
        "architecture",
        "model",
        "platformVersion",
        "fullVersionList",
      ]);
    }
    return mobileContext;
  },
};

export default Object.freeze(logger);
