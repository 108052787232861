<script>
  import Button from "@smui/button";
  import Checkbox from "@smui/checkbox";
  import FormField from "@smui/form-field";
  import Paper, { Content } from "@smui/paper";
  import Tab, { Label } from "@smui/tab";
  import TabBar from "@smui/tab-bar";
  import Textfield from "@smui/textfield";
  import imageCompression from "browser-image-compression";
  import { format as formatDate } from "date-fns";
  import { ja as localeJa } from "date-fns/locale";
  import { escape } from "html-escaper";
  import { HTTPError } from "ky";
  import sleep from "sleep-promise";
  import { getContext, onDestroy, onMount, tick } from "svelte";
  import { fade } from "svelte/transition";
  import { _ } from "svelte-i18n";

  import ConfirmDialog from "~/components/ConfirmDialog.svelte";
  import Footer from "~/components/Footer.svelte";
  import Header from "~/components/Header.svelte";
  import HelpAfterTrouble from "~/components/help/HelpAfterTrouble.svelte";
  import HelpBase from "~/components/help/HelpBase.svelte";
  import HelpDeliveryComplete from "~/components/help/HelpDeliveryComplete.svelte";
  import HelpNonDelivery from "~/components/help/HelpNonDelivery.svelte";
  import HelpTakeOverToDriver from "~/components/help/HelpTakeOverToDriver.svelte";
  import QrCodeScanner from "~/components/QrCodeScanner.svelte";
  import RoleIcon from "~/components/RoleIcon.svelte";
  import additionalDataForDeliverylist from "~/libs/additionalDataForDeliverylist";
  import addressUtils from "~/libs/addressUtils";
  import { beep, destroyAudioContext, initAudioContext } from "~/libs/audio";
  import backendApi, { OfflineException } from "~/libs/backendApi";
  import {
    ABSENCE,
    ACCEPT_DENIED,
    AVAILABLE_DROP_OFF_LOCATIONS,
    AddressTypeForMap,
    CONTEXT_KEY_APP,
    CONTEXT_KEY_USER,
    ConfirmDialogTypes,
    DeliveryRecordTypes,
    DropOffLocation,
    LastOperationTypes,
    NO_PICKUP_TIMEFRAME,
    NotificationCategory,
    PAYMENT_NOT_POSSIBLE,
    PaymentNotPossibleDetectionTypes,
    RedeliveryDateSpecifyMethod,
    STATUS_DELIVERED,
    STATUS_OUT_FOR_DELIVERY,
    TROUBLE_LOST_OR_DAMAGED,
    TROUBLE_NON_ADDRESS,
  } from "~/libs/constants";
  import deliveryListUtils from "~/libs/deliveryListUtils";
  import imageFormatUtil, {
    STANDARD_COMPRESSION_OPTION,
  } from "~/libs/imageFormatUtil";
  import loadingProgress from "~/libs/loadingProgress";
  import logger from "~/libs/logger";
  import notificationHistoryUtils from "~/libs/notificationHistoryUtils";
  import pageRouter from "~/libs/pageRouter";
  import { CodeType } from "~/libs/qrCodeScanner";
  import requestsQueueForRetry from "~/libs/requestsQueueForRetry";
  import {
    deliveryTarget,
    lastOperationType,
    needToGettingKnowledge,
    readonly,
  } from "~/libs/stores";
  import { reserveUpdateDeliveryRecordsAndSyncBackend } from "~/libs/syncOperationState";
  import { toast } from "~/libs/toast";
  import {
    formatTrackingNumber,
    parseCodabarEmbeddedTrackingNumber,
    parseQRCodeEmbeddedTrackingNumber,
  } from "~/libs/trackingNumberUtils";
  import ChangeCalculation from "~/pages/Update/ChangeCalculation.svelte";
  import DeliveryCompletionProcedure from "~/pages/Update/DeliveryCompletionProcedure.svelte";
  import KnowledgeRegistration from "~/pages/Update/KnowledgeRegistration.svelte";
  import AbsenceNotificationDialog from "~/pages/Update/NonDeliveryActions/AbsenceNotificationDialog.svelte";
  import ConfirmReasonDialog from "~/pages/Update/NonDeliveryActions/ConfirmReasonDialog.svelte";
  import NonDeliveryActions from "~/pages/Update/NonDeliveryActions.svelte";
  import PersonalMemoDialog from "~/pages/Update/PersonalMemoDialog.svelte";
  import PodPhotoTaker from "~/pages/Update/PodPhotoTaker.svelte";

  /** @type {import("~/libs/commonTypes").AppContext} */
  const appContext = getContext(CONTEXT_KEY_APP);

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {import("~/libs/commonTypes").DeliveryPackage} 配達対象の荷物情報 */
  let deliveryPackage = $deliveryTarget;

  /** @type {Array<import("~/libs/commonTypes").DeliveryPackage>} */
  let shippingList = userContext.deliveryList ?? [];

  /** @type {import("~/libs/commonTypes").DeliveryPackage} 更新対象の荷物 */
  const updateTarget = deliveryListUtils.findByTrackingNumber(
    shippingList,
    deliveryPackage.trackingNumber,
  );

  /** @type {number} 宅配ドライバーが選択した受け渡し方法（下位コンポーネントからのbind用） */
  let actualPackageDropPlace;
  /** @type {string} 宅配ボックスのボックス番号（下位コンポーネントからのbind用） */
  let lockerNumber;
  /** @type {string} 宅配ボックスの暗証番号（下位コンポーネントからのbind用） */
  let lockerPin;
  /** @type {0 | 1 | 2 | 3 | 4 | 5 | 6} 配達不可の場合の理由コード */
  let extraEventType;
  /** @type {0 | 1 | 2 | 3 | 4 | 5 | 6} 登録失敗時の配達不可理由コード */
  let syncFailureExtraEventType;

  /** 配達証明写真のFile（下位コンポーネントからのbind用） @type {File} */
  let podPhotoFile;

  /** @type {boolean} 汚損フラグ（チェックボックス） */
  let damaged = false;

  /** @type {boolean} 送り状のQRコードとの照合が完了したか否か */
  let qrCodeVerificationCompleted = false;

  /**
   * 確認ダイアログコンポーネント（送り状のQRコードとの照合をスキップするか否かの確認用）のインスタンス
   * @type {ConfirmDialog}
   */
  let qrCodeVerificationSkipConfirmDialog;

  /** @type {AbsenceNotificationDialog} 不在通知を送信するためのダイアログ */
  let absentNotificationDialog;

  /** @type {ConfirmDialog} 配達完了登録の最終確認を行うためのダイアログ */
  let deliveryCompletionConfirmDialog;

  /** @type {ConfirmDialog} 配達メモの登録・編集を行うためのダイアログ */
  let knowledgeEditDialog;

  /** @type {ConfirmDialog} 引継ぎ確認を行うためのダイアログ */
  let handoverConfirmDialog;

  /** @type {ConfirmDialog} 戻る確認ダイアログ */
  let backConfirmDialog;

  /** @type {QrCodeScanner} QrCodeScannerコンポーネントのインスタンス */
  let qrCodeScanner;
  /** @type {boolean} QRコードを読み取り中か否か */
  let qrCodeScanInProgress = false;
  /** @type {Set<string>} 読み取り済の送り状番号の一覧 */
  const scannedTrackingNumbers = new Set();

  /** @type {import("svelte").ComponentType<HelpBase>} */
  let helpBase;
  /** @type {import("svelte").ComponentType<HelpAfterTrouble | HelpDeliveryComplete | HelpNonDelivery | HelpTakeOverToDriver>} */
  let helpContents;
  let displayingHelp;

  /** @type {boolean} ステータスチェックの結果、配達継続不可の場合にtrue */
  let cannotBeDelivered = false;

  /** @type {boolean} ステータスチェックの結果、返品対象の場合にtrue */
  let isReturn = false;

  /** 配達不可の理由を確認・選択するダイアログ @type {ConfirmReasonDialog} */
  let confirmReasonDialog;

  /** 個人メモを登録するダイアログ @type {PersonalMemoDialog} */
  let personalMemoDialog;

  /** @type {import("~/libs/constants").PaymentNotPossibleDetectionTypes} 代引き金額支払不可の検知タイプ */
  let paymentNotPossibleType;

  /** @type {import("~/libs/constants").acceptDeniedReasonTypes} 受取拒否の理由区分 */
  let acceptDeniedReasonType;

  /** @type {number} 紛失した荷物の個数 */
  let numberOfLost;

  /** @type {string} アクティブなタブ */
  let active = "配達完了登録";

  /**
   * 1週間分の再配達受取可能時間帯が全て「受け取り不可」の場合にtrue
   * 1週間分の再配達受け取り可能時間帯が未設定、また再配達希望日時を1つ指定してもらった場合もtrueになるが、その場合は用途無し
   * @type {boolean}
   */
  let noAvailablePickupDateOfWeek = true;
  if (
    deliveryPackage.specifiedPickupDatetime &&
    deliveryPackage.specifiedPickupDatetime["availablePickupDatetime"]
  ) {
    deliveryPackage.specifiedPickupDatetime["availablePickupDatetime"].forEach(
      (element) => {
        if (element["timeFrame"] !== NO_PICKUP_TIMEFRAME) {
          noAvailablePickupDateOfWeek = false;
        }
      },
    );
  }

  /** @type {string} 部屋ごとのナレッジ入力欄にバインドされる内容 */
  let editableByAddressKnowledge = deliveryPackage.shippingKnowledge?.byAddress
    ? deliveryPackage.shippingKnowledge.byAddress.memo
    : "";

  /** @type {object} 変更前の部屋単位ナレッジ(更新有無チェック用) */
  let changeBeforeByAddressKnowledge = {
    memo: editableByAddressKnowledge,
    id: deliveryPackage.shippingKnowledge?.byAddress?.id,
  };

  /** @type {string} 近隣ごとのナレッジ入力欄にバインドされる内容 */
  let editableNeighborhoodKnowledge = "";

  /** @type {object} 変更前の近隣ナレッジ(更新有無チェック用) */
  let changeBeforeNeighborhoodKnowledge = {
    memo: "",
    id: undefined,
  };
  if (deliveryPackage.shippingKnowledge?.neighborhoods) {
    for (const neighborhood of deliveryPackage.shippingKnowledge
      .neighborhoods) {
      if (neighborhood.sameAddress) {
        editableNeighborhoodKnowledge = neighborhood.memo;
        changeBeforeNeighborhoodKnowledge = {
          memo: editableNeighborhoodKnowledge,
          id: neighborhood.id,
        };
        break;
      }
    }
  }

  /** @type {import("~/libs/backendApi").RegisterByAddressKnowledgeRequest} 新規登録する場合の住所（部屋）ごとの配達ナレッジ */
  let newByAddressKnowledge;
  /** @type {import("~/libs/backendApi").UpdateByAddressKnowledgeRequest} 更新する場合の住所（部屋）ごとの配達ナレッジ */
  let updatedByAddressKnowledge;
  /** @type {import("~/libs/backendApi").RegisterNeighborhoodKnowledgeRequest} 新規登録する場合のエリアで共有する配達ナレッジ */
  let newNeighborhoodKnowledge;
  /** @type {import("~/libs/backendApi").UpdateNeighborhoodKnowledgeRequest} 更新する場合のエリアで共有する配達ナレッジ */
  let updatedNeighborhoodKnowledge;

  /** @type {string} 個人メモ */
  let personalMemo = deliveryPackage.personalMemo;

  /** @type {string} 荷姿や送り状の写真のデータURL */
  let photoDataUrlForPackaging;

  /** @type {() => void} データ破棄確認後に実行する処理 */
  let functionAfterDiscard;

  /** 配達登録リクエスト(JSONデータ・リカバリログ用) */
  let statusUpdateJsonData;
  /** @type {boolean} 複数個口の場合の確認チェック */
  let multipleChecked = false;

  /** 「他のドライバーに引継ぐ」ボタンのアクションかどうか @type {boolean} */
  let isHandoverAction = false;

  /** 引継ぎの連絡事項 @type {string} */
  let handoverMemo = "";

  /** 引継ぎ登録を行う際の通信欄登録内容 @type {string} */
  let internalMessageOfHandover = "";

  /** @type {FormData} 配達登録リクエスト(Formデータ) */
  let StatusUpdateFormData;

  /** ナレッジ登録リクエスト(JSONデータ・リカバリログ用) */
  let registShippingKnowledgeJsonData;

  /** ナレッジ更新リクエスト(JSONデータ・リカバリログ用) */
  let updateShippingKnowledgeJsonData;

  /** @type {boolean} 紛失登録を行なう場合にtrue */
  let lostRegistrationFlag = false;

  /** @type {boolean} 紛失調査中の場合にtrue */
  let searchingForPackageFlag = deliveryPackage.searchingForPackage ?? false;

  /** @type {boolean} 一時的に読取専用モードを解除する場合にtrue */
  let isReadOnlySuppressed = false;

  /**
   * ステータス更新APIのレスポンスでUpdateFailedが応答された例外
   */
  class UpdateFailedException extends Error {
    constructor(message) {
      super(message);
      this.name = "UpdateFailedException";
    }
  }

  (() => {
    if (Number.isInteger(deliveryPackage.cashOnDeliveryAmount)) {
      actualPackageDropPlace = DropOffLocation.HANDOVER;
    }
  })();

  (async () => {
    // 個人メモ用画像がある場合は取得
    photoDataUrlForPackaging =
      await additionalDataForDeliverylist.getPersonalMemoPhoto(
        deliveryPackage.trackingNumber,
      );

    if (deliveryPackage.statusText !== "未") {
      // 未配達以外の場合はステータスチェックをスキップ
      if (Number.isInteger(deliveryPackage.returnStatus)) {
        cannotBeDelivered = true;
        isReturn = true;
      }
      return;
    }
    try {
      let outputMessage;

      // 最新情報を取得
      const responseBody = await backendApi.getShipmentInfo(
        deliveryPackage.trackingNumber,
      );
      // 配送ステータスチェック（持出し中であること）
      if (responseBody.status !== STATUS_OUT_FOR_DELIVERY) {
        // 配送ステータスが持出し中でない場合は配達継続不可
        outputMessage = $_("errors.isCannotBeDeliveredPackage_2", {
          values: {
            trackingNumber: formatTrackingNumber(
              deliveryPackage.trackingNumber,
            ),
          },
        });
        // 配達リストから削除
        await deleteFromDeliveryList();

        // 配達リストから削除する荷物の付加データも削除
        additionalDataForDeliverylist.deleteByTrackingNumber(
          deliveryPackage.trackingNumber,
        );
      }
      // 返品ステータスチェック（返品対象でないこと）
      else if (Number.isInteger(responseBody.returnStatus)) {
        // 返品ステータスがある場合は配達継続不可
        outputMessage = $_("errors.returnRequiredPackage", {
          values: {
            trackingNumber: formatTrackingNumber(
              deliveryPackage.trackingNumber,
            ),
          },
        });
        // 持ち戻り対象に変更
        await updateToBeTakeback(
          responseBody.returnStatus,
          responseBody.returnReason,
        );
        isReturn = true;
      }

      if (outputMessage) {
        toast.info(outputMessage, { initial: 0, popsWhenPageMoved: true });
        notificationHistoryUtils.deleteAndAddHistory(
          userContext.loginUser.username,
          NotificationCategory.INFO,
          outputMessage,
        );
        // 配達継続不可フラグを立てる
        cannotBeDelivered = true;
      }
    } catch (error) {
      // 最新情報の取得に失敗した場合でも配達の継続は可能なためログ出力のみ
      logger.error(
        "[Update] 最新情報の取得時にエラーが発生しました",
        {
          username: userContext.loginUser?.username,
          trackingNumber: deliveryPackage.trackingNumber,
        },
        error,
      );
    }
  })();

  /** @type {boolean} 配達完了登録に進めない入力状態の場合はtrue */
  $: disallowGoAheadToDeliveryCompletion =
    !judgeAllowGoAheadToDeliveryCompletionetion(
      // @ts-ignore
      actualPackageDropPlace,
      lockerNumber,
      lockerPin,
      podPhotoFile,
      multipleChecked,
    );

  // extraEventTypeの更新に伴い実行するリアクティブ処理
  $: {
    onChangeExtraEventType(
      // @ts-ignore
      extraEventType,
    );
  }

  /**
   * 配達完了登録に進める入力状態か否かを判定する
   * @returns {boolean} 配達完了登録に進める入力状態か否か
   */
  function judgeAllowGoAheadToDeliveryCompletionetion() {
    if (!AVAILABLE_DROP_OFF_LOCATIONS.includes(actualPackageDropPlace)) {
      // 利用可能な受け渡し方法以外はNG
      return false;
    }
    if (actualPackageDropPlace !== DropOffLocation.HANDOVER) {
      if (!podPhotoFile) {
        // 手渡し以外（置き配）は写真が必須
        return false;
      }
    } else {
      if (deliveryPackage.customer.signatureRequired && !podPhotoFile) {
        // 手渡しで署名が必要な場合は写真が必須
        return false;
      }
    }
    if (actualPackageDropPlace === DropOffLocation.LOCKER) {
      if (lockerNumber != null && !lockerNumber) {
        // 「ボックス番号を指定」がONの場合は必須
        return false;
      }
      if (lockerPin != null && !lockerPin) {
        // 「暗証番号を指定」がONの場合は必須
        return false;
      }
    }
    if (deliveryPackage.numberOfPackages > 1 && !multipleChecked) {
      // 複数個口の場合は複数個確認チェックが必須
      return false;
    }
    return true;
  }

  /**
   * extraEventTypeが更新された場合に呼び出されるリアクティブハンドラー
   */
  function onChangeExtraEventType() {
    if (Number.isInteger(extraEventType)) {
      if (!appContext.firstTroubleRegistered) {
        displayingHelp = true;
        helpContents = HelpAfterTrouble;
        helpBase = HelpBase;
        appContext.firstTroubleRegistered = true;
        appContext.store();
      } else {
        goToList();
      }
    }
  }

  /**
   * 配達登録・ナレッジ登録を行って配達リスト画面に遷移する
   */
  const goToList = loadingProgress.wrapAsync(async () => {
    /** エラーが発生した場合にtrue */
    let errorOccurred = false;
    /** 配達リストから削除する必要がある場合にtrue */
    let needDeleteFromDeliveryList = false;
    /** 更新不可リストに移動する必要がある場合にtrue */
    let needMoveUnupdatableList = false;
    /** @type {import("~/libs/commonTypes").Shipment} */
    let updatedPackage;
    let requestedTimeStamp;

    try {
      // 現在時刻を保持
      requestedTimeStamp = Date.now();
      updatedPackage = await execStatusUpdateApi(requestedTimeStamp);
    } catch (error) {
      if (error instanceof UpdateFailedException) {
        // 配達ステータス更新APIのレスポンスでUpdateFailedが応答された場合
        toast.error(error.message);
        notificationHistoryUtils.deleteAndAddHistory(
          userContext.loginUser.username,
          NotificationCategory.ERROR,
          error.message,
        );
        errorOccurred = true;
        needDeleteFromDeliveryList = true;
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 401
      ) {
        toast.error($_("errors.unauthorized"));
        return;
      } else if (
        error instanceof HTTPError &&
        error.response &&
        error.response.status == 403
      ) {
        toast.error($_("errors.forbidden"));
        return;
      } else if (
        error instanceof HTTPError &&
        error.response &&
        String(error.response.status).substring(0, 1) == "4"
      ) {
        // 401,403以外の400系エラー(リトライしてもエラー解消が見込めない)の場合
        // DataDogにリカバリ用のログを送信し、更新不可リストに移動
        /** @type {string} */
        let unattendedDeliveryPhoto;
        if (podPhotoFile) {
          const compressedPhoto = await imageCompression.getDataUrlFromFile(
            imageFormatUtil.isCompressed(podPhotoFile)
              ? podPhotoFile
              : await imageCompression(
                  podPhotoFile,
                  STANDARD_COMPRESSION_OPTION,
                ),
          );
          unattendedDeliveryPhoto = compressedPhoto.substring(
            compressedPhoto.indexOf("base64,") + 7,
          );
        }
        logger.error(
          "[Update] 配達登録でエラーが発生しました",
          {
            needRecoveryCheck: true,
            username: userContext.loginUser?.username,
            requestedJsonData: statusUpdateJsonData,
            unattendedDeliveryPhoto: unattendedDeliveryPhoto,
          },
          error,
        );

        // 荷物を配達リストから更新不可リストに移動
        errorOccurred = true;
        needMoveUnupdatableList = true;
        toast.error($_("errors.unableToRegister"));
      } else {
        // オフライン状態やサーバーエラー応答等が発生した場合
        // ユーザーに「リトライ」or「後で送信」の選択を促す
        if (error instanceof OfflineException) {
          toast.recommendSendLater(
            $_("errors.offline"),
            sendFailedRequestLater,
          );
        } else {
          toast.recommendSendLater(
            $_("errors.defaultMessage"),
            sendFailedRequestLater,
          );
        }
        syncFailureExtraEventType = extraEventType;
        extraEventType = undefined;
        return;
      }
    }

    if (
      editableByAddressKnowledge !== changeBeforeByAddressKnowledge.memo ||
      editableNeighborhoodKnowledge !== changeBeforeNeighborhoodKnowledge.memo
    ) {
      // ナレッジに変更があった場合のみ登録
      await registOrUpdateKnowledgeApi();
      await updateKnowledgeOfLocalStorage();
    }

    if (!errorOccurred) {
      await updateLocalStorage(updatedPackage);

      if (Number.isInteger(extraEventType)) {
        if (
          extraEventType !== ABSENCE ||
          (extraEventType === ABSENCE &&
            !deliveryPackage.redeliveryContext?.notificationResend)
        )
          // 配達不可で配達実績を更新
          // ※再配達日時の再登録の場合を除く
          reserveUpdateDeliveryRecordsAndSyncBackend(
            userContext,
            requestedTimeStamp,
            determineRecordType(),
            {
              trackingNumber: deliveryPackage.trackingNumber,
              address: deliveryListUtils.getReferencedAddress(deliveryPackage),
              location: {
                latitude: deliveryPackage.latlon?.latitude,
                longitude: deliveryPackage.latlon?.longitude,
              },
              extraEvent: extraEventType,
            },
          );
      } else {
        // 配達完了で配達実績を更新
        reserveUpdateDeliveryRecordsAndSyncBackend(
          userContext,
          requestedTimeStamp,
          DeliveryRecordTypes.DELIVERED,
          {
            trackingNumber: deliveryPackage.trackingNumber,
            address: deliveryListUtils.getReferencedAddress(deliveryPackage),
            location: {
              latitude: deliveryPackage.latlon?.latitude,
              longitude: deliveryPackage.latlon?.longitude,
            },
          },
        );
      }

      if (Number.isInteger(extraEventType)) {
        if (extraEventType === TROUBLE_LOST_OR_DAMAGED) {
          if (lostRegistrationFlag) {
            notificationHistoryUtils.deleteAndAddHistory(
              userContext.loginUser.username,
              NotificationCategory.INFO,
              $_("message.lostRegistrationInfomation", {
                values: {
                  trackingNumber: formatTrackingNumber(
                    deliveryPackage.trackingNumber,
                  ),
                  address: `${addressUtils
                    .trimPrefecture(deliveryPackage.receiverAddress1)
                    .substring(0, 6)}...`,
                  name: deliveryPackage.receiverName.split(/\s+/)[0],
                },
              }),
            );
            toast.info($_("message.lostRegistrationCompleted"));
          } else if (searchingForPackageFlag) {
            toast.info($_("message.lostRegistrationInterrupted"));
          } else {
            toast.info($_("message.damageRegisterationCompleted"));
          }
        } else {
          toast.info(
            $_("message.troubleRegistered", {
              values: {
                extraEvent: $_(
                  `pages.Update.extraEventTypeLabel.${extraEventType}`,
                ),
              },
            }),
          );
        }
      } else if (!Number.isInteger(extraEventType)) {
        if (isReadOnlySuppressed) {
          toast.info($_("message.deliveryCompletedWithSuppressedReadonly"));
        } else {
          toast.info($_("message.deliveryCompleted"));
        }
      }

      // 手元に荷物が残っていない場合
      if (deliveryListUtils.canFinishWork(shippingList)) {
        // ドライバーの稼働状況をシステムに同期
        reserveUpdateDeliveryRecordsAndSyncBackend(
          userContext,
          requestedTimeStamp,
          DeliveryRecordTypes.ALL_DELIVERED,
        );

        // 配達リスト画面遷移後に業務終了のダイアログを表示する
        lastOperationType.set(LastOperationTypes.ALL_DELIVERED);
      }

      // 正常に登録が完了した場合、送信待ちリクエストをチェック
      const syncFailureList = userContext.syncFailureList ?? [];
      if (syncFailureList.length > 0) {
        // 送信待ちリクエストがある場合はリトライを試みる
        for (let i = 0; i < syncFailureList.length; i++) {
          if (syncFailureList[i].retryId) {
            try {
              // API実行の間隔を空ける
              await sleep(500);

              const result = await requestsQueueForRetry.resendById(
                syncFailureList[i].retryId,
              );

              // 該当の荷物を更新失敗リストから配達リストに戻す
              await updateLocalStorageForRetry(
                result.trackingNumber,
                result.updateResponse,
                result.updateRequestJson,
              );

              i--;
            } catch (error) {
              // エラー発生時はリトライを中断(自動リトライなので特にエラーメッセージは表示しない)
              console.error(error);
              break;
            }
          }
        }
      }
    } else {
      if (needDeleteFromDeliveryList) {
        await deleteFromDeliveryList();

        // 配達リストから削除する荷物の付加データも削除
        additionalDataForDeliverylist.deleteByTrackingNumber(
          deliveryPackage.trackingNumber,
        );
      }
      if (needMoveUnupdatableList) {
        await moveToSyncFailureList();
      }
    }
    pageRouter.moveToList();
  });

  /**
   * 配達ステータス更新APIを実行する
   * @param {number} [requestedTimeStamp] リクエスト時刻（ステータス更新後に配達実績を同期する時は必須）
   * @returns {Promise<import("~/libs/commonTypes").Shipment>} 更新後の荷物情報
   */
  async function execStatusUpdateApi(requestedTimeStamp) {
    let statusUpdateEvent = { response: true };
    let events = [];
    let sendStatus;
    let sendDamaged;
    let sendActualPackageDropPlace;
    let sendShippingPartnerTrouble;
    let sendDeliveryBoxNumber;
    let sendDeliveryBoxPin;
    let sendRedeliveryContext;
    let sendNotification;
    let signaturePhoto;
    let acceptDeniedMessage;
    let handoverMessage;
    let lost;
    let lostMessage;
    /** @type {Blob} */
    let blob1;
    let blob2;
    const isIncrementDeliveryAttempt = needIncrementNumberOfDeliveryAttempts();

    if (Number.isInteger(extraEventType)) {
      // 配達不可の場合
      sendStatus = STATUS_OUT_FOR_DELIVERY;
      if (lostRegistrationFlag) {
        lost = true;

        if (deliveryPackage.numberOfPackages > 1) {
          // 複数口の場合
          lostMessage =
            "------システムより登録\n【登録日時】" +
            formatDate(new Date(), "yyyy-MM-dd HH:mm:ss", {
              locale: localeJa,
            }) +
            "\n【登録者】" +
            userContext.loginUser.username +
            "\n【事象】" +
            deliveryPackage.numberOfPackages +
            "個口の荷物のうち、" +
            numberOfLost +
            "個を紛失しました。" +
            "\n------\n";
        }
      } else {
        if (extraEventType === ABSENCE && deliveryPackage.redeliveryContext) {
          sendRedeliveryContext = deliveryPackage.redeliveryContext;

          if (!deliveryPackage.redeliveryContext["notificationResend"]) {
            sendShippingPartnerTrouble = extraEventType;
          }
        } else {
          sendShippingPartnerTrouble = extraEventType;
        }
        if (
          deliveryPackage.customer.emailNotificationRequired &&
          extraEventType === ABSENCE
        ) {
          sendNotification = { type: 1 };
        }
        if (extraEventType === ACCEPT_DENIED) {
          acceptDeniedMessage =
            "------システムより登録\n【登録日時】" +
            formatDate(new Date(), "yyyy-MM-dd HH:mm:ss", {
              locale: localeJa,
            }) +
            "\n【事象】" +
            $_(`classes.acceptDeniedReason.${acceptDeniedReasonType}`) +
            "\n------\n";
        }
      }
    } else if (isHandoverAction) {
      // 他のドライバーに引継ぐ場合
      sendStatus = STATUS_OUT_FOR_DELIVERY;
      handoverMessage = internalMessageOfHandover;
    } else {
      // 配達完了の場合
      sendStatus = STATUS_DELIVERED;

      if (deliveryPackage.customer.emailNotificationRequired) {
        sendNotification = { type: 0 };
      }

      // 事前に汚損フラグが立っている場合はチェックボックスが非表示のため値を送信しない
      if (!deliveryPackage.damaged) {
        sendDamaged = damaged;
      }

      if (actualPackageDropPlace !== null) {
        sendActualPackageDropPlace = actualPackageDropPlace;
      } else {
        sendActualPackageDropPlace = deliveryPackage.packageDropPlace;
      }

      if (sendActualPackageDropPlace === DropOffLocation.LOCKER) {
        lockerNumber ? (sendDeliveryBoxNumber = lockerNumber) : "";
        lockerPin ? (sendDeliveryBoxPin = lockerPin) : "";
      }

      if (
        podPhotoFile &&
        sendActualPackageDropPlace !== DropOffLocation.HANDOVER
      ) {
        blob1 = imageFormatUtil.isCompressed(podPhotoFile)
          ? podPhotoFile
          : await imageCompression(podPhotoFile, STANDARD_COMPRESSION_OPTION);
      } else if (podPhotoFile && deliveryPackage.customer.signatureRequired) {
        let compressedPhoto = await imageCompression.getDataUrlFromFile(
          imageFormatUtil.isCompressed(podPhotoFile)
            ? podPhotoFile
            : await imageCompression(podPhotoFile, STANDARD_COMPRESSION_OPTION),
        );
        signaturePhoto = compressedPhoto.substring(
          compressedPhoto.indexOf("base64,") + 7,
        );
        compressedPhoto = undefined;
      }
    }
    let deliveredEvent = {
      trackingNumber: deliveryPackage.trackingNumber,
      status: sendStatus,
      actualPackageDropPlace: sendActualPackageDropPlace,
      extraEventType: sendShippingPartnerTrouble,
      deliveryBoxNumber: sendDeliveryBoxNumber,
      deliveryBoxPin: sendDeliveryBoxPin,
      isIncrementDeliveryAttempt: isIncrementDeliveryAttempt,
    };
    if (sendRedeliveryContext) {
      deliveredEvent.redeliveryContext = sendRedeliveryContext;
    }
    if (sendNotification) {
      deliveredEvent.notification = sendNotification;
    }
    if (sendDamaged !== undefined) {
      deliveredEvent.damaged = sendDamaged;
    }
    if (signaturePhoto) {
      deliveredEvent.signaturePhoto = signaturePhoto;
    }
    if (lost) {
      deliveredEvent.lost = lost;
    }
    if (lostMessage) {
      if (deliveryPackage.delivererInternalMessage?.length > 0) {
        deliveredEvent.delivererInternalMessage =
          deliveryPackage.delivererInternalMessage + "\n" + lostMessage;
      } else {
        deliveredEvent.delivererInternalMessage = lostMessage;
      }
    }
    if (acceptDeniedMessage) {
      if (deliveryPackage.ecDelivererInternalMessage?.length > 0) {
        deliveredEvent.ecDelivererInternalMessage =
          deliveryPackage.ecDelivererInternalMessage +
          "\n" +
          acceptDeniedMessage;
      } else {
        deliveredEvent.ecDelivererInternalMessage = acceptDeniedMessage;
      }
    }
    if (handoverMessage) {
      if (deliveryPackage.delivererInternalMessage?.length > 0) {
        deliveredEvent.delivererInternalMessage =
          deliveryPackage.delivererInternalMessage + "\n" + handoverMessage;
      } else {
        deliveredEvent.delivererInternalMessage = handoverMessage;
      }
    }
    events.push(deliveredEvent);
    statusUpdateJsonData = deliveredEvent;
    statusUpdateEvent["events"] = events;
    blob2 = new Blob([JSON.stringify(statusUpdateEvent)], {
      type: "application/json",
    });

    StatusUpdateFormData = new FormData();
    if (blob1) {
      StatusUpdateFormData.append("delivered-photo", blob1);
    }
    StatusUpdateFormData.append("status-update-event", blob2);

    const updateResponse = await backendApi.updateShipmentStatus(
      StatusUpdateFormData,
      requestedTimeStamp,
    );
    if (updateResponse.updateFailed) {
      let errorMessage;
      if (sendStatus == STATUS_DELIVERED) {
        errorMessage = $_("errors.isCannotBeDeliveredPackage", {
          values: {
            trackingNumber: formatTrackingNumber(
              deliveryPackage.trackingNumber,
            ),
          },
        });
      } else {
        errorMessage = $_("errors.isCannotBeTroublePackage", {
          values: {
            trackingNumber: formatTrackingNumber(
              deliveryPackage.trackingNumber,
            ),
          },
        });
      }
      throw new UpdateFailedException(errorMessage);
    }

    return updateResponse.success[0];
  }

  /** ナレッジの登録・更新を行う */
  async function registOrUpdateKnowledgeApi() {
    if (editableByAddressKnowledge !== changeBeforeByAddressKnowledge.memo) {
      // 部屋ごとのナレッジに変更があった場合
      if (changeBeforeByAddressKnowledge.id === undefined) {
        // 変更前のナレッジが未登録の場合は新規登録用に設定
        newByAddressKnowledge = {
          address: {
            [AddressTypeForMap.REGISTERED]: addressUtils.joinWithSpace(
              deliveryPackage.receiverAddress1,
              deliveryPackage.receiverAddress2,
            ),
            [AddressTypeForMap.INPUTTED]: deliveryPackage.enteredAddress,
            [AddressTypeForMap.CORRECTED]:
              deliveryPackage.correctedReceiverAddress,
          }[deliveryPackage.addressForMap],
          memo: editableByAddressKnowledge,
          updatedAt: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
          receiverName: formattedReceiverNameForKnowledgeArea(
            deliveryPackage.receiverName,
          ),
        };
      } else {
        // 変更前のナレッジが登録済みの場合は更新用に設定
        updatedByAddressKnowledge = {
          id: changeBeforeByAddressKnowledge.id,
          memo: editableByAddressKnowledge,
          updatedAt: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
          receiverName: formattedReceiverNameForKnowledgeArea(
            deliveryPackage.receiverName,
          ),
        };
      }
    }
    if (
      editableNeighborhoodKnowledge !== changeBeforeNeighborhoodKnowledge.memo
    ) {
      let memoToRegister = editableNeighborhoodKnowledge;
      // 近隣ナレッジに変更があった場合
      if (changeBeforeNeighborhoodKnowledge.id === undefined) {
        // 変更前のナレッジが未登録の場合は新規登録用に設定
        newNeighborhoodKnowledge = {
          latitude: deliveryPackage.latlon.latitude,
          longitude: deliveryPackage.latlon.longitude,
          memo: memoToRegister,
          updatedAt: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
          address: {
            [AddressTypeForMap.REGISTERED]: addressUtils.joinWithSpace(
              deliveryPackage.receiverAddress1,
              deliveryPackage.receiverAddress2,
            ),
            [AddressTypeForMap.INPUTTED]: deliveryPackage.enteredAddress,
            [AddressTypeForMap.CORRECTED]:
              deliveryPackage.correctedReceiverAddress,
          }[deliveryPackage.addressForMap],
        };
      } else {
        // 変更前のナレッジが登録済みの場合は更新用に設定
        updatedNeighborhoodKnowledge = {
          id: changeBeforeNeighborhoodKnowledge.id,
          memo: memoToRegister,
          updatedAt: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
        };
      }
    }

    try {
      if (newByAddressKnowledge || newNeighborhoodKnowledge) {
        await execRegistShippingKnowledgeApi(
          newByAddressKnowledge,
          newNeighborhoodKnowledge,
        );
      }

      if (updatedByAddressKnowledge || updatedNeighborhoodKnowledge) {
        await execUpdateShippingKnowledgeApi(
          updatedByAddressKnowledge,
          updatedNeighborhoodKnowledge,
        );
      }
    } catch (error) {
      if (registShippingKnowledgeJsonData) {
        logger.error(
          "[Regist] 宅配ナレッジ登録でエラーが発生しました",
          {
            needRecoveryCheck: true,
            username: userContext.loginUser?.username,
            requestedJsonData: encodeURIComponent(
              JSON.stringify(registShippingKnowledgeJsonData),
            ),
          },
          error,
        );
      } else {
        logger.error(
          "[Update] 宅配ナレッジ更新でエラーが発生しました",
          {
            needRecoveryCheck: true,
            username: userContext.loginUser?.username,
            requestedJsonData: encodeURIComponent(
              JSON.stringify(updateShippingKnowledgeJsonData),
            ),
          },
          error,
        );
      }
    }
  }

  /**
   * 配達ナレッジ登録APIを実行
   * @param {import("~/libs/backendApi").RegisterByAddressKnowledgeRequest} newByAddressKnowledge
   * @param {import("~/libs/backendApi").RegisterNeighborhoodKnowledgeRequest} newNeighborhoodKnowledge
   */
  async function execRegistShippingKnowledgeApi(
    newByAddressKnowledge,
    newNeighborhoodKnowledge,
  ) {
    registShippingKnowledgeJsonData = {
      byAddress: newByAddressKnowledge,
      neighborhood: newNeighborhoodKnowledge,
    };
    await backendApi.registerShippingNnowledge(registShippingKnowledgeJsonData);
  }

  /**
   * 配達ナレッジ更新APIを実行
   * @param {import("~/libs/backendApi").UpdateByAddressKnowledgeRequest} updatedByAddressKnowledge
   * @param {import("~/libs/backendApi").UpdateNeighborhoodKnowledgeRequest} updatedNeighborhoodKnowledge
   */
  async function execUpdateShippingKnowledgeApi(
    updatedByAddressKnowledge,
    updatedNeighborhoodKnowledge,
  ) {
    updateShippingKnowledgeJsonData = {
      byAddress: updatedByAddressKnowledge,
      neighborhood: updatedNeighborhoodKnowledge,
    };
    await backendApi.updateShippingNnowledge(updateShippingKnowledgeJsonData);
  }

  async function updateLocalStorage(updatedPackage) {
    if (updatedPackage) {
      deliveryListUtils.updateDeliveryList(
        shippingList,
        updatedPackage,
        statusUpdateJsonData,
        searchingForPackageFlag,
      );
    }

    if (isHandoverAction) {
      // 引継ぎの場合は引継ぎ待ちフラグを立てる
      updateTarget.isRegisteredHandover = true;
    }

    userContext.deliveryList = shippingList;
    userContext.store();
  }

  /**
   * ナレッジを配達リストに反映する
   */
  async function updateKnowledgeOfLocalStorage() {
    // ローカルのshippingListを更新する
    if (deliveryPackage.shippingKnowledge) {
      // 既にナレッジの登録がある場合（同じ住所のナレッジ、近隣のナレッジどちらかのみの場合もあり）
      if (newByAddressKnowledge) {
        // 同じ住所のナレッジが新規登録の場合
        deliveryPackage.shippingKnowledge.byAddress = {
          memo: newByAddressKnowledge.memo,
          updatedBy: userContext.loginUser.displayName,
          updatedAt: newByAddressKnowledge.updatedAt,
          receiverName: newByAddressKnowledge.receiverName,
        };
      } else if (updatedByAddressKnowledge) {
        // 同じ住所のナレッジが更新の場合
        deliveryPackage.shippingKnowledge.byAddress.memo =
          updatedByAddressKnowledge.memo;
        deliveryPackage.shippingKnowledge.byAddress.updatedBy =
          userContext.loginUser.displayName;
        deliveryPackage.shippingKnowledge.byAddress.updatedAt =
          updatedByAddressKnowledge.updatedAt;
      }
      if (newNeighborhoodKnowledge) {
        // 近隣ナレッジが新規登録の場合
        if (deliveryPackage.shippingKnowledge.neighborhoods.length > 0) {
          deliveryPackage.shippingKnowledge.neighborhoods.push({
            memo: newNeighborhoodKnowledge.memo,
            updatedBy: userContext.loginUser.displayName,
            updatedAt: newNeighborhoodKnowledge.updatedAt,
            address: newNeighborhoodKnowledge.address,
          });
        } else {
          deliveryPackage.shippingKnowledge.neighborhoods = [
            {
              memo: newNeighborhoodKnowledge.memo,
              updatedBy: userContext.loginUser.displayName,
              updatedAt: newNeighborhoodKnowledge.updatedAt,
              address: newNeighborhoodKnowledge.address,
            },
          ];
        }
        // 次回の配達リスト表示時、強制的にバックエンドと同期
        needToGettingKnowledge.set(true);
      } else if (updatedNeighborhoodKnowledge) {
        // 近隣ナレッジが更新の場合
        const indexOfNeighborhoodKnowledge =
          deliveryPackage.shippingKnowledge.neighborhoods.findIndex(
            (e) => e.id === updatedNeighborhoodKnowledge.id,
          );
        if (indexOfNeighborhoodKnowledge >= 0) {
          // 同じidの近隣ナレッジが登録済みの場合
          deliveryPackage.shippingKnowledge.neighborhoods[
            indexOfNeighborhoodKnowledge
          ].memo = updatedNeighborhoodKnowledge.memo;
          deliveryPackage.shippingKnowledge.neighborhoods[
            indexOfNeighborhoodKnowledge
          ].updatedBy = userContext.loginUser.displayName;
          deliveryPackage.shippingKnowledge.neighborhoods[
            indexOfNeighborhoodKnowledge
          ].updatedAt = updatedNeighborhoodKnowledge.updatedAt;
        } else {
          deliveryPackage.shippingKnowledge.neighborhoods.push({
            memo: newNeighborhoodKnowledge.memo,
            updatedBy: userContext.loginUser.displayName,
            updatedAt: newNeighborhoodKnowledge.updatedAt,
            address: newNeighborhoodKnowledge.address,
          });
        }
        // 次回の配達リスト表示時、強制的にバックエンドと同期
        needToGettingKnowledge.set(true);
      }
    } else {
      // ナレッジが未登録の場合
      /** @type {import("~/libs/commonTypes").ShippingKnowledge} */
      let shippingKnowledge = {
        byAddress: null,
        neighborhoods: [],
      };
      if (newByAddressKnowledge) {
        shippingKnowledge.byAddress = {
          memo: newByAddressKnowledge.memo,
          updatedBy: userContext.loginUser.displayName,
          updatedAt: newByAddressKnowledge.updatedAt,
          receiverName: newByAddressKnowledge.receiverName,
        };
      }
      if (newNeighborhoodKnowledge) {
        shippingKnowledge.neighborhoods = [
          {
            memo: newNeighborhoodKnowledge.memo,
            updatedBy: userContext.loginUser.displayName,
            updatedAt: newNeighborhoodKnowledge.updatedAt,
            address: newNeighborhoodKnowledge.address,
          },
        ];
        // 次回の配達リスト表示時、強制的にバックエンドと同期
        needToGettingKnowledge.set(true);
      }
      deliveryPackage.shippingKnowledge = shippingKnowledge;
    }
    deliveryPackage = deliveryPackage;
    updateTarget.shippingKnowledge = deliveryPackage.shippingKnowledge;

    shippingList = shippingList;
    userContext.deliveryList = shippingList;
    userContext.store();
  }

  async function updateLocalStorageForRetry(
    trackingNumber,
    updateResponse,
    updateRequestJson,
  ) {
    const deliveryList = userContext.deliveryList ?? [];
    const tmpSyncFailureList = userContext.syncFailureList ?? [];
    const index = tmpSyncFailureList.findIndex(
      (e) => e.trackingNumber === trackingNumber,
    );

    if (updateResponse.updateFailed) {
      // 更新結果がupdateFailedだった場合、メッセージを表示
      toast.error(
        $_("errors.failedAutoResend", {
          values: {
            trackingNumber: formatTrackingNumber(trackingNumber),
          },
        }),
      );
    } else {
      // 更新結果が成功だった場合
      // 更新失敗リストから配達リストにコピー
      deliveryList.push(tmpSyncFailureList[index]);

      // 更新情報を反映
      deliveryListUtils.updateDeliveryList(
        deliveryList,
        updateResponse.success[0],
        updateRequestJson,
      );

      toast.info(
        $_("message.autoResendSuccessful", {
          values: {
            trackingNumber: formatTrackingNumber(trackingNumber),
          },
        }),
      );
    }
    // 更新失敗リストから削除
    tmpSyncFailureList.splice(index, 1);

    userContext.deliveryList = deliveryList;
    userContext.syncFailureList = tmpSyncFailureList;
    userContext.store();
  }

  /**
   * 配達試行回数をカウントアップする必要があるかを判定する
   * @returns {boolean}
   */
  function needIncrementNumberOfDeliveryAttempts() {
    if (
      (!Number.isInteger(extraEventType) && !isHandoverAction) ||
      extraEventType == TROUBLE_NON_ADDRESS ||
      (extraEventType == ABSENCE &&
        (!deliveryPackage.redeliveryContext ||
          !deliveryPackage.redeliveryContext["notificationResend"])) ||
      extraEventType == ACCEPT_DENIED ||
      (extraEventType == PAYMENT_NOT_POSSIBLE &&
        paymentNotPossibleType ==
          PaymentNotPossibleDetectionTypes.AFTER_ARRIVAL)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * extraEventの設定値に応じてdeliveryRecordsの値を決定する
   * @returns {DeliveryRecordTypes}
   */
  function determineRecordType() {
    if (
      extraEventType === ABSENCE ||
      extraEventType === ACCEPT_DENIED ||
      (extraEventType == PAYMENT_NOT_POSSIBLE &&
        paymentNotPossibleType ==
          PaymentNotPossibleDetectionTypes.AFTER_ARRIVAL)
    ) {
      // 不在・置き配不可, 受取拒否, 支払い不可(到着後)の場合は「3:配達不可」
      return DeliveryRecordTypes.UNDELIVERABLE;
    } else {
      // それ以外の場合は「6:配達不可(移動なし)」
      return DeliveryRecordTypes.UNDELIVERABLE_NO_MOVE;
    }
  }

  /** 配達が継続できなくなった荷物をリストから除外する処理 */
  async function deleteFromDeliveryList() {
    const shippingList = userContext.deliveryList ?? [];
    deliveryListUtils.deleteByTrackingNumber(
      shippingList,
      deliveryPackage.trackingNumber,
    );

    userContext.deliveryList = shippingList;
    userContext.store();
  }

  /**
   * 配達登録に失敗した荷物を同期失敗リストに移動する
   * @param {string} [retryId] リトライ用DBに登録したリクエストデータのID
   **/
  async function moveToSyncFailureList(retryId) {
    const syncFailureList = userContext.syncFailureList ?? [];
    /** @type {import("~/libs/commonTypes").SyncFailureDeliveryPackage} */
    const syncFailureDeliveryPackage = Object.assign(deliveryPackage, {
      failedTime: formatDate(new Date(), "yyyy-MM-dd HH:mm:ss"),
    });
    if (Number.isInteger(retryId)) {
      syncFailureDeliveryPackage.retryId = retryId;
    }
    if (Number.isInteger(syncFailureExtraEventType)) {
      syncFailureDeliveryPackage.reasonForUndeliverable =
        syncFailureExtraEventType;
    }
    syncFailureList.push(syncFailureDeliveryPackage);
    userContext.syncFailureList = syncFailureList;
    userContext.store();

    // 配達リストから削除
    deleteFromDeliveryList();
  }

  /**
   * 返品対象の荷物を持ち戻り対象に更新する処理
   * @param {0 | 1 | 2 | 3} returnStatus
   * @param {0 | 1 | 2 | 3 | 4 | 5 | 6} returnReason
   */
  async function updateToBeTakeback(returnStatus, returnReason) {
    const shippingList = userContext.deliveryList ?? [];
    for (let i = 0; i < shippingList.length; i++) {
      if (shippingList[i].trackingNumber === deliveryPackage.trackingNumber) {
        shippingList[i].returnStatus = returnStatus;
        shippingList[i].returnReason = returnReason;
        shippingList[i].statusText = "不";
        break;
      }
    }
    userContext.deliveryList = shippingList;
    userContext.store();

    // 画面表示用のステータス更新
    deliveryPackage.returnStatus = returnStatus;
    deliveryPackage.returnReason = returnReason;
  }

  onMount(async () => {
    await tick();

    if (!appContext.firstDeliveryCompleteOpened) {
      helpContents = HelpDeliveryComplete;
      helpBase = HelpBase;
      appContext.firstDeliveryCompleteOpened = true;
      appContext.store();
    }
  });

  onDestroy(() => {
    returnBackToReadonly();
    destroyAudioContext();
  });

  /**
   * @param {string} decodedText QRコードから読み取ったテキスト
   * @param {boolean} needsDecode
   * @param {import("~/libs/qrCodeScanner").CodeType} codeType
   */
  function onScanSuccess(decodedText, needsDecode, codeType) {
    const currentScannedTrackingNumbersSize = scannedTrackingNumbers.size;

    /** @type {string} */
    let scannedTrackingNumber = decodedText;
    /** @type {Error} */
    let parseError;
    try {
      if (needsDecode) {
        scannedTrackingNumber =
          codeType === CodeType.QRCODE
            ? parseQRCodeEmbeddedTrackingNumber(decodedText)
            : parseCodabarEmbeddedTrackingNumber(decodedText);
      }
    } catch (error) {
      parseError = error;
    } finally {
      scannedTrackingNumbers.add(scannedTrackingNumber);
    }

    if (scannedTrackingNumbers.size === currentScannedTrackingNumbersSize) {
      // スキャン済みのデータは無視
      return;
    } else if (parseError) {
      toast.error(parseError.message);
      return;
    }

    if (scannedTrackingNumber !== deliveryPackage.trackingNumber) {
      toast.error(
        $_("errors.scannedTrackingNumberIsNotCorrectly", {
          values: {
            trackingNumber: formatTrackingNumber(scannedTrackingNumber),
          },
        }),
        { popsWhenPageMoved: true },
      );
      return;
    }

    beep();
    qrCodeScanner.stopScanning().then(() => {
      qrCodeScanInProgress = false;
      qrCodeVerificationCompleted = true;
      scannedTrackingNumbers.clear();
    });
  }

  function onScanError(errorMessage, error) {
    console.log("onScanError:", errorMessage, error);
  }

  const statusUpdate = async () => {
    goToList();
  };

  function goToBackPage() {
    pageRouter.moveToList();
  }

  /** 戻るボタン押下時の処理 */
  function confirmDiscardAndGoBack() {
    if (
      podPhotoFile ||
      (actualPackageDropPlace === DropOffLocation.LOCKER &&
        lockerNumber != null) ||
      (actualPackageDropPlace === DropOffLocation.LOCKER && lockerPin != null)
    ) {
      // 写真をアップロード済み、もしくは宅配ボックス情報を入力済みの場合は確認ダイアログを表示
      functionAfterDiscard = goToBackPage;
      backConfirmDialog.openDialog();
    } else {
      goToBackPage();
    }
  }

  /**
   * フッタボタン押下時の処理
   * @param {() => void} pageTransition
   */
  function confirmDiscardAndChangePage(pageTransition) {
    if (
      podPhotoFile ||
      (actualPackageDropPlace === DropOffLocation.LOCKER &&
        lockerNumber != null) ||
      (actualPackageDropPlace === DropOffLocation.LOCKER && lockerPin != null)
    ) {
      // 写真をアップロード済み、もしくは宅配ボックス情報を入力済みの場合は確認ダイアログを表示
      functionAfterDiscard = pageTransition;
      backConfirmDialog.openDialog();
    } else {
      pageTransition();
    }
  }

  function clickConfirm() {
    helpBase = null;
    helpContents = null;
    if (displayingHelp) {
      goToList();
    }
  }

  /**
   * ナレッジ共有エリアの荷受人名をフォーマットする。
   * @param {string} name
   * @returns {string}
   */
  function formattedReceiverNameForKnowledgeArea(name) {
    // 半角・全角スペースの位置を見つける
    const spaceIndex = name.indexOf(" ");
    const fullWidthSpaceIndex = name.indexOf("　");
    const index = fullWidthSpaceIndex !== -1 ? fullWidthSpaceIndex : spaceIndex;

    // スペースが見つかった場合は姓のみ返し、見つからなければフルネームを返す
    if (index !== -1) {
      return name.slice(0, index);
    } else {
      return name;
    }
  }

  function updatePersonalMemo(event) {
    personalMemo = event.detail.personalMemo;
    photoDataUrlForPackaging = event.detail.photoDataUrlForPackaging;
  }

  /**
   * プッシュで受信した情報を画面に反映する
   * @param {string} correctedReceiverAddress
   * @param {string} delivererInternalMessage
   * @param {import("~/libs/commonTypes").DateAndTimeFrame} adjustedRedeliveryDatetime
   */
  export function updateDeliveryPackageInfo(
    correctedReceiverAddress,
    delivererInternalMessage,
    adjustedRedeliveryDatetime,
  ) {
    if (correctedReceiverAddress) {
      deliveryPackage.correctedReceiverAddress = correctedReceiverAddress;
      deliveryPackage.addressForMap = AddressTypeForMap.CORRECTED;
    }
    if (delivererInternalMessage) {
      deliveryPackage.delivererInternalMessage = delivererInternalMessage;
    }
    if (adjustedRedeliveryDatetime) {
      if (deliveryPackage.redeliveryContext) {
        deliveryPackage.redeliveryContext.adjustedRedeliveryDatetime =
          adjustedRedeliveryDatetime;
      } else {
        deliveryPackage.redeliveryContext = {
          redeliveryDatetimeSpecMethod: null,
          timeFramePreset: null,
          redeliveryUnavailability: null,
          adjustedRedeliveryDatetime: adjustedRedeliveryDatetime,
          notificationResend: false,
        };
      }
      if (deliveryPackage.specifiedPickupDatetime) {
        delete deliveryPackage.specifiedPickupDatetime;
      }
    }
  }

  /**
   * 送信に失敗した場合に後で送信する準備を行う
   */
  async function sendFailedRequestLater() {
    // DB登録してリスト画面に遷移
    const id = await registerRequestsQueueForRetry();
    await moveToSyncFailureList(id);
    toast.info($_("message.sendLater"));
    pageRouter.moveToList();
  }

  /**
   * リトライ用のリクエストを登録する
   * @returns {Promise<string>} 登録時に自動採番されたID
   */
  async function registerRequestsQueueForRetry() {
    const serialized = [...StatusUpdateFormData];
    return await requestsQueueForRetry.updateShipmentStatus(
      deliveryPackage.trackingNumber,
      serialized,
    );
  }

  /**
   * 配達不可登録のタブを初回表示した際にヘルプを表示する
   */
  $: if (active === "配達不可登録" && !appContext.firstNonDeliveryOpened) {
    helpContents = HelpNonDelivery;
    helpBase = HelpBase;
    appContext.firstNonDeliveryOpened = true;
    appContext.store();
  }

  /**
   * 引継ぎのタブを初回表示した際にヘルプを表示する
   */
  $: if (active === "引継ぎ" && !appContext.firstTakeOverToDriverOpened) {
    helpContents = HelpTakeOverToDriver;
    helpBase = HelpBase;
    appContext.firstTakeOverToDriverOpened = true;
    appContext.store();
  }

  /**
   * 一時的に読取専用モードを抑制し、配達登録ができるようにする
   * フッターの持ち出し、持ち戻り・返品はアクティブにしない
   */
  async function suppresseReadonly() {
    isReadOnlySuppressed = true;
    userContext.readOnly = false;
    userContext.store();

    logger.info("[配達登録] 一時的に読取専用モードを解除しました", {
      username: userContext.loginUser?.username,
    });
  }

  /**
   * 読み取り専用モードに戻す
   */
  async function returnBackToReadonly() {
    if (isReadOnlySuppressed) {
      isReadOnlySuppressed = false;
      userContext.readOnly = true;
      readonly.set(true);
      userContext.store();
    }
  }
</script>

<div class="mainContentsWrapper">
  <Header>
    <svelte:fragment slot="left">
      {#if userContext.canSwitchRole()}
        <RoleIcon />
      {/if}
    </svelte:fragment>
    <svelte:fragment slot="center">配達登録</svelte:fragment>
  </Header>

  <main in:fade>
    <div class="lateralMarginWrapper">
      <!--
        セクション1. 配達基本情報
        -->
      <section class="deliveryInfoArea">
        <table class="infoTable">
          <tr>
            <th>送り状番号</th>
            <td class="trackingNumberCell">
              <div>
                {formatTrackingNumber(deliveryPackage.trackingNumber)}
              </div>
              {#if deliveryPackage.numberOfPackages > 1}
                <!--複数個口の場合-->
                <div class="multiple">
                  （複数口：{deliveryPackage.numberOfPackages}個）
                </div>
              {/if}
              <button
                class="memoButton"
                on:click={() => {
                  personalMemoDialog.openDialog();
                }}
              >
                <span class="material-icons">edit</span><span>個人メモ</span>
              </button>
            </td>
          </tr>
          {#if !deliveryPackage.unacquired}
            <tr>
              <th>ご住所</th>
              <td>
                {#if deliveryPackage.addressForMap === AddressTypeForMap.INPUTTED && deliveryPackage.enteredAddress}
                  {addressUtils.trimPrefecture(deliveryPackage.enteredAddress)}
                {:else if deliveryPackage.addressForMap === AddressTypeForMap.CORRECTED && deliveryPackage.correctedReceiverAddress}
                  <span class="highlight">
                    <span
                      >{addressUtils.trimPrefecture(
                        deliveryPackage.correctedReceiverAddress,
                      )}</span
                    >
                    <span class="note">※訂正された住所</span>
                  </span>
                {:else}
                  {addressUtils.trimPrefecture(
                    deliveryPackage.receiverAddress1,
                  ) +
                    (deliveryPackage.receiverAddress2
                      ? " " + deliveryPackage.receiverAddress2
                      : "")}
                {/if}
              </td>
            </tr>
            <tr>
              <th>お名前</th>
              <td class="nameCell">
                {deliveryPackage.receiverName}
                {#if deliveryPackage.receiverTel}
                  <button
                    class="callButton"
                    on:click={() => {
                      window.location.href = `tel:${deliveryPackage.receiverTel}`;
                    }}
                    ><span class="material-icons">call</span><span
                      >電話をかける</span
                    ></button
                  >
                {/if}
              </td>
            </tr>
            {#if deliveryPackage.statusText !== "済"}
              {#if deliveryPackage.desiredDate || deliveryPackage.desiredTime}
                <tr class="em">
                  <th>初回指定</th>
                  <td>
                    {#if deliveryPackage.desiredDate}
                      {formatDate(
                        new Date(deliveryPackage.desiredDate),
                        "M/d(E)",
                        {
                          locale: localeJa,
                        },
                      )}
                    {:else}
                      日付指定なし
                    {/if}
                    {#if deliveryPackage.desiredTime}
                      {parseInt(deliveryPackage.desiredTime.slice(0, 2)) +
                        "～" +
                        parseInt(deliveryPackage.desiredTime.slice(2, 4)) +
                        "時"}
                    {:else}
                      時間指定なし
                    {/if}
                  </td>
                </tr>
              {/if}
              {#if deliveryPackage.redeliveryContext}
                <tr class="em">
                  <th>再配達希望</th>
                  <td>
                    {#if deliveryPackage.redeliveryContext["adjustedRedeliveryDatetime"]}
                      <!-- 電話で調整済みの場合 -->
                      {#if new Date(deliveryPackage.redeliveryContext["adjustedRedeliveryDatetime"]["date"]).toDateString() === new Date().toDateString()}本日
                      {/if}
                      {formatDate(
                        new Date(
                          deliveryPackage.redeliveryContext[
                            "adjustedRedeliveryDatetime"
                          ]["date"],
                        ),
                        "M/d(E)",
                        { locale: localeJa },
                      )}
                      {#if deliveryPackage.redeliveryContext["adjustedRedeliveryDatetime"]["timeFrame"]}
                        {Number(
                          deliveryPackage.redeliveryContext[
                            "adjustedRedeliveryDatetime"
                          ]["timeFrame"].substring(0, 2),
                        )}～{Number(
                          deliveryPackage.redeliveryContext[
                            "adjustedRedeliveryDatetime"
                          ]["timeFrame"].substring(2, 4),
                        )}時
                      {:else}
                        時間指定なし
                      {/if}
                    {:else if deliveryPackage.redeliveryContext["redeliveryDatetimeSpecMethod"] === RedeliveryDateSpecifyMethod.DESIRED_DATE_AND_TIME && deliveryPackage.specifiedPickupDatetime && deliveryPackage.specifiedPickupDatetime["desiredRedeliveryDatetime"]}
                      <!-- 希望日時を1つ指定してもらうよう依頼し、荷受人による設定済み -->
                      {#if deliveryPackage.specifiedPickupDatetime["desiredRedeliveryDatetime"]["timeFrame"] === NO_PICKUP_TIMEFRAME}
                        <!-- 受け取れる日時無し -->
                        {formatDate(
                          new Date(
                            deliveryPackage.specifiedPickupDatetime[
                              "desiredRedeliveryDatetime"
                            ]["date"],
                          ),
                          "M/d(E)",
                          { locale: localeJa },
                        )}まで受け取り可能時間帯なし
                      {:else}
                        <!-- 受け取れる日時あり -->
                        {#if new Date(deliveryPackage.specifiedPickupDatetime["desiredRedeliveryDatetime"]["date"]).toDateString() === new Date().toDateString()}本日
                        {/if}
                        {formatDate(
                          new Date(
                            deliveryPackage.specifiedPickupDatetime[
                              "desiredRedeliveryDatetime"
                            ]["date"],
                          ),
                          "M/d(E)",
                          { locale: localeJa },
                        )}
                        {#if deliveryPackage.specifiedPickupDatetime["desiredRedeliveryDatetime"]["timeFrame"]}
                          {Number(
                            deliveryPackage.specifiedPickupDatetime[
                              "desiredRedeliveryDatetime"
                            ]["timeFrame"].substring(0, 2),
                          )}～{Number(
                            deliveryPackage.specifiedPickupDatetime[
                              "desiredRedeliveryDatetime"
                            ]["timeFrame"].substring(2, 4),
                          )}時
                        {:else}
                          時間指定なし
                        {/if}
                      {/if}
                    {:else if deliveryPackage.redeliveryContext["redeliveryDatetimeSpecMethod"] === RedeliveryDateSpecifyMethod.AVAILABLE_RECEIVE_TIME_ZONE_OF_WEEK && deliveryPackage.specifiedPickupDatetime && deliveryPackage.specifiedPickupDatetime["availablePickupDatetime"]}
                      <!-- 一週間分の受け取り可能時間帯を設定してもらう依頼し、荷受人による設定済み -->
                      {#if noAvailablePickupDateOfWeek}
                        <!-- 全日「受け取り不可」 -->
                        {formatDate(
                          new Date(
                            deliveryPackage.specifiedPickupDatetime[
                              "availablePickupDatetime"
                            ][
                              deliveryPackage.specifiedPickupDatetime[
                                "availablePickupDatetime"
                              ].length - 1
                            ]["date"],
                          ),
                          "M/d(E)",
                          { locale: localeJa },
                        )}まで受け取り可能時間帯なし
                      {:else}
                        <!-- 受け取り可能な日がある -->
                        {#each deliveryPackage.specifiedPickupDatetime["availablePickupDatetime"] as datetime, i}
                          {formatDate(new Date(datetime["date"]), "M/d(E)", {
                            locale: localeJa,
                          })}
                          {#if datetime["timeFrame"] === NO_PICKUP_TIMEFRAME}
                            受け取り不可
                          {:else}
                            {Number(
                              datetime["timeFrame"].substring(0, 2),
                            )}時以降
                          {/if}
                          {#if i !== deliveryPackage.specifiedPickupDatetime["availablePickupDatetime"].length - 1}
                            <br />
                          {/if}
                        {/each}
                      {/if}
                    {:else}
                      設定依頼中
                    {/if}
                  </td>
                </tr>
              {/if}
              {#if deliveryPackage.packageDropPlace != undefined}
                <tr class="em">
                  <th>受渡希望</th>
                  <td
                    >{$_(
                      `classes.deliveryMethod.${deliveryPackage.packageDropPlace}`,
                    )}</td
                  >
                </tr>
              {/if}
            {/if}
          {/if}
        </table>
      </section>
      {#if deliveryPackage.statusText === "未" && !deliveryPackage.damaged}
        <div class="damageCheck">
          <FormField>
            <Checkbox bind:checked={damaged} />
            <span slot="label">配達中に荷物が汚損した</span>
          </FormField>
        </div>
      {/if}

      {#if Number.isInteger(deliveryPackage.cashOnDeliveryAmount) && !cannotBeDelivered && deliveryPackage.statusText !== "済"}
        <div class="cautionOfCashOnDelivery">
          代引き指定のお荷物です<br />
          料金<em>{deliveryPackage.cashOnDeliveryAmount.toLocaleString()}円</em
          >と引き換えてください
        </div>
      {:else if isReturn}
        <div class="cautionOfReturn">
          <strong>返品対象のお荷物です</strong><br />
          <span class="returnReason">返品理由</span>{$_(
            `classes.returnReason.${deliveryPackage.returnReason}`,
          )}
        </div>
      {/if}

      <!--
          セクション2. 配達履歴
          -->
      {#if deliveryPackage.extraEvent || deliveryPackage.statusText === "済"}
        <section class="deliveryHistoryArea">
          <div class="deliveryHistoryAreaTitle">配達履歴</div>
          <div class="deliveryHistoryContents">
            <div class="deliveryHistoryTable">
              {#if deliveryPackage.extraEvent}
                {#each deliveryPackage.extraEvent as event}
                  <div class="row">
                    <div class="dateAndTime">
                      {formatDate(new Date(event.time), "M/d(E) H:mm", {
                        locale: localeJa,
                      })}
                    </div>
                    <div class="history">
                      {$_(
                        "pages.Update.extraEventTypeLabel." +
                          event.extraEventType,
                      )}
                    </div>
                  </div>
                {/each}
              {/if}
              {#if deliveryPackage.statusText === "済"}
                <div class="row">
                  <div class="dateAndTime">
                    {formatDate(
                      new Date(deliveryPackage.deliveredTimestamp),
                      "M/d(E) H:mm",
                      {
                        locale: localeJa,
                      },
                    )}
                  </div>
                  <div class="history">配達完了</div>
                </div>
              {/if}
            </div>
          </div>
        </section>
      {/if}
      <!--
          セクション3. 個人メモ
          -->
      {#if personalMemo || photoDataUrlForPackaging}
        <section class="personalMemoArea">
          <p class="personalMemoAreaTitle">個人メモ</p>
          <div class="personalMemoAreaContent">
            {#if personalMemo}
              {@html escape(personalMemo).replace(/\n/g, "<br />")}
            {/if}
            <!-- プレビュー画面（荷姿や送り状の写真がアップロードされている時のみ表示） -->
            {#if photoDataUrlForPackaging}
              <div class="previewArea">
                <img
                  src={photoDataUrlForPackaging}
                  class="previewImage"
                  alt="preview"
                  style="width: 100%; margin-top: 4px;"
                />
              </div>
            {/if}
          </div>
        </section>
      {/if}

      <!--
          セクション4. ナレッジ
          -->
      {#if deliveryPackage.shippingKnowledge}
        <section class="knowledgeArea">
          <div class="knowledgeAreaHeader">
            <p class="knowledgeAreaTitle">配達メモ(共有事項)</p>
            <button
              class="editButton"
              on:click={() => {
                knowledgeEditDialog.openDialog();
              }}
            >
              <span class="material-icons">edit</span><span>編集する</span>
            </button>
          </div>

          <div class="knowledgeGroup">
            {#if deliveryPackage.shippingKnowledge.byAddress}
              <p class="knowledgeMessage">
                {@html escape(
                  deliveryPackage.shippingKnowledge.byAddress.memo,
                ).replace(/\n/g, "<br />")}
              </p>
              <div class="knowledgeInfo">
                <p>
                  <span class="material-icons knowledgeIcon">house</span
                  >住居(<span
                    class:nameHasChanged={!deliveryPackage.receiverName.startsWith(
                      deliveryPackage.shippingKnowledge.byAddress.receiverName,
                    )}
                    >{deliveryPackage.shippingKnowledge.byAddress
                      .receiverName}様</span
                  >宅)配達時のメモ
                </p>
                <p>
                  更新日時:{deliveryPackage.shippingKnowledge.byAddress
                    .updatedAt}
                </p>
                <p>
                  更新者:{deliveryPackage.shippingKnowledge.byAddress.updatedBy}
                </p>
              </div>
            {/if}
            {#if deliveryPackage.shippingKnowledge.neighborhoods}
              {#each deliveryPackage.shippingKnowledge.neighborhoods as neighborHoodKnowledge, index}
                {#if deliveryPackage.shippingKnowledge.byAddress || index > 0}
                  <div class="border" />
                {/if}
                <p class="knowledgeMessage">
                  {@html escape(neighborHoodKnowledge.memo).replace(
                    /\n/g,
                    "<br />",
                  )}
                </p>
                <div class="knowledgeInfo">
                  <p>
                    <span class="material-icons knowledgeIcon"
                      >maps_home_work</span
                    >近隣({addressUtils.trimPrefecture(
                      neighborHoodKnowledge.address,
                    )})配達時のメモ
                  </p>
                  <p>更新日時:{neighborHoodKnowledge.updatedAt}</p>
                  <p>
                    更新者:{neighborHoodKnowledge.updatedBy}
                  </p>
                </div>
              {/each}
            {/if}
          </div>
        </section>
      {:else if deliveryPackage.statusText !== "未"}
        <div class="knowledgeRegistButtonArea">
          <Button
            variant="outlined"
            color="secondary"
            on:click={() => {
              knowledgeEditDialog.openDialog();
            }}
          >
            <span class="material-icons">edit</span><span
              >配達メモ(共有事項)を登録する</span
            >
          </Button>
        </div>
      {/if}

      <!--
        セクション5. 連絡事項
        -->
      {#if deliveryPackage.ecDelivererInternalMessage || deliveryPackage.delivererInternalMessage}
        <section class="communicationMessageArea">
          {#if deliveryPackage.ecDelivererInternalMessage}
            <div>
              <p class="communicationMessageAreaTitle">EC事業者との通信欄</p>
              <div class="message">
                <p class="text">
                  {@html escape(
                    deliveryPackage.ecDelivererInternalMessage,
                  ).replace(/\n/g, "<br />")}
                </p>
              </div>
            </div>
          {/if}
          {#if deliveryPackage.delivererInternalMessage}
            <div>
              <p class="communicationMessageAreaTitle">配送事業者内の通信欄</p>
              <div class="message">
                <p class="text">
                  {@html escape(
                    deliveryPackage.delivererInternalMessage,
                  ).replace(/\n/g, "<br />")}
                </p>
              </div>
            </div>
          {/if}
        </section>
      {/if}

      {#if Number.isInteger(deliveryPackage.cashOnDeliveryAmount) && deliveryPackage.statusText !== "済"}
        <!-- IF 代引き指定の場合 -->
        <div class="changeCalculationArea">
          <!-- おつりの計算 -->
          <ChangeCalculation
            cashOnDeliveryAmount={deliveryPackage.cashOnDeliveryAmount}
          />
        </div>
      {/if}

      {#if (deliveryPackage.statusText === "未" || deliveryPackage.statusText === "不") && !cannotBeDelivered && deliveryPackage.redeliveryContext}
        <!--
          IF 未配達の荷物（未）または持ち戻り対象の荷物（不）の荷物の場合、かつ配達継続可能の場合、かつ再配達のコンテキストが存在する場合
          -->
        <div class="resendAbsenceNoticeArea">
          <p>
            {#if deliveryPackage.redeliveryContext.adjustedRedeliveryDatetime || deliveryPackage.specifiedPickupDatetime}
              再配達希望日時変更の連絡があった場合
            {:else}
              再配達希望日時の連絡があった場合
            {/if}
          </p>
          <Button
            color="secondary"
            variant="unelevated"
            on:click={() => {
              absentNotificationDialog.openDialog();
            }}
          >
            {#if deliveryPackage.redeliveryContext.adjustedRedeliveryDatetime || deliveryPackage.specifiedPickupDatetime}
              再配達希望日時を変更する
            {:else}
              再配達希望日時を登録する
            {/if}
          </Button>
        </div>
      {/if}

      <div class="border" />

      {#if deliveryPackage.statusText === "未" && userContext.readOnly}
        <section class="readonlyNoticeArea">
          <p class="message">読取専用モードのため配達登録ができません</p>
          <button class="releaseReadOnlyButton" on:click={suppresseReadonly}
            >読取専用モードを一時的に解除する</button
          >
        </section>
      {/if}

      {#if deliveryPackage.statusText === "未" && !cannotBeDelivered && !userContext.readOnly}
        <!--
        IF 未配達の荷物（未）、かつ配達継続可能の場合
        -->

        <!--
        セクション6. 荷物のスキャンによる整合性チェック
        -->
        {#if !qrCodeScanInProgress}
          {#if !qrCodeVerificationCompleted}
            <section class="qrCodeVerificationArea" out:fade>
              <p class="message">誤配防止のため荷物をスキャンしてください。</p>
              <button
                class="qrButton"
                on:click={loadingProgress.wrapAsync(async () => {
                  qrCodeScanInProgress = true;
                  initAudioContext();
                  await qrCodeScanner.startScanning(() => {
                    tick().then(() => {
                      document
                        .getElementById("qrCodeScannerArea")
                        ?.scrollIntoView({ behavior: "smooth" });
                    });
                  });
                })}>荷物をスキャン</button
              >
              <div class="otherButtonArea">
                <button
                  class="otherButton"
                  on:click={() => {
                    qrCodeVerificationSkipConfirmDialog.openDialog();
                  }}>読み取れない場合はこちら</button
                >
              </div>
            </section>
          {:else}
            <section class="qrCodeVerificationCompletedArea" in:fade>
              <p class="icon">
                <span class="material-icons md-32">task_alt</span>
              </p>
              <p class="message">
                アプリ上で選択した荷物と送り状の記載内容が一致することを確認しました。
              </p>
            </section>
          {/if}
        {/if}
        <QrCodeScanner
          bind:this={qrCodeScanner}
          onScanSuccessHandler={onScanSuccess}
          onScanErrorHandler={onScanError}
          enableInputForm={false}
        />

        <!--
        セクション7. 配達完了登録／通常の配達不可登録
        -->
        {#if qrCodeVerificationCompleted}
          <div class="border" />

          <div class="shippingAreaWrapper">
            <TabBar
              tabs={["配達完了登録", "配達不可登録", "引継ぎ"]}
              let:tab
              bind:active
            >
              <Tab {tab} name={tab}>
                <Label>{tab}</Label>
              </Tab>
            </TabBar>

            {#if active === "配達完了登録"}
              <Paper variant="unelevated">
                <Content>
                  <section class="shippingArea">
                    {#if Number.isInteger(deliveryPackage.cashOnDeliveryAmount)}
                      <div class="warningText">
                        <p>
                          代引き指定のお荷物のため、<br />必ず<strong
                            >手渡し</strong
                          >で配達してください。
                        </p>
                      </div>
                    {:else}
                      <!-- 受け渡し方法の選択 -->
                      <DeliveryCompletionProcedure
                        bind:actualPackageDropPlace
                        bind:lockerNumber
                        bind:lockerPin
                      />
                      <div class="border" style="margin-top: 15px;" />
                    {/if}

                    <!-- 配達証明写真 -->
                    <PodPhotoTaker
                      {deliveryPackage}
                      {actualPackageDropPlace}
                      bind:podPhotoFile
                    />

                    <!-- 確認・登録 -->
                    {#if !deliveryPackage.customer.emailNotificationRequired && actualPackageDropPlace === DropOffLocation.LOCKER}
                      <div class="deliveryNoticeCaution">
                        <p>
                          本荷物は荷受人に配達完了メールを送れないため、<em
                            >不在連絡票</em
                          >に必要事項を記載のうえ投函してください。
                        </p>

                        <table class="deliveryNoticeTable">
                          <tr>
                            <th>荷受人名</th>
                            <td>{deliveryPackage.receiverName}</td>
                          </tr>
                          <tr>
                            <th>Box番号</th>
                            <td>{lockerNumber ? lockerNumber : ""}</td>
                          </tr>
                          <tr>
                            <th>暗証番号</th>
                            <td>{lockerPin ? lockerPin : ""}</td>
                          </tr>
                          <tr>
                            <th>証明番号</th>
                            <td
                              >宅配ボックスに表示のある場合はその番号を記載してください</td
                            >
                          </tr>
                          <tr>
                            <th>ご依頼主名</th>
                            <td>{deliveryPackage.shipperName}</td>
                          </tr>
                          <tr>
                            <th>配達日時</th>
                            <td>{formatDate(new Date(), "M月d日 H時m分")}</td>
                          </tr>
                          <tr>
                            <th>送り状番号</th>
                            <td
                              >{formatTrackingNumber(
                                deliveryPackage.trackingNumber,
                              )}</td
                            >
                          </tr>
                        </table>
                      </div>
                    {/if}
                    {#if deliveryPackage.numberOfPackages > 1}
                      <div class="multipleCheck">
                        <FormField>
                          <Checkbox bind:checked={multipleChecked} />
                          <span slot="label"
                            >荷物が{deliveryPackage.numberOfPackages}個あることを確認した</span
                          >
                        </FormField>
                      </div>
                    {/if}
                    <Button
                      class="registerButton"
                      style="background-color={disallowGoAheadToDeliveryCompletion
                        ? '#b1e6e5'
                        : '#018786'}"
                      variant="unelevated"
                      bind:disabled={disallowGoAheadToDeliveryCompletion}
                      on:click={deliveryCompletionConfirmDialog.openDialog}
                      >登録内容を確認する</Button
                    >
                  </section>
                </Content>
              </Paper>
            {:else if active === "配達不可登録"}
              <Paper variant="unelevated">
                <Content style="background-color: #f5f5f5; margin-top: 16px;">
                  <NonDeliveryActions
                    bind:extraEventType
                    bind:paymentNotPossibleType
                    bind:acceptDeniedReasonType
                    cashOnDeliveryAmount={deliveryPackage.cashOnDeliveryAmount}
                    bind:numberOfLost
                    bind:lostRegistrationFlag
                    bind:searchingForPackageFlag
                    bind:editableByAddressKnowledge
                    bind:changeBeforeByAddressKnowledge
                    bind:editableNeighborhoodKnowledge
                    bind:changeBeforeNeighborhoodKnowledge
                  />
                </Content>
              </Paper>
            {:else if active === "引継ぎ"}
              <Paper variant="unelevated">
                <Content style="background-color: #f5f5f5; margin-top: 16px;">
                  <div class="handoverActionArea">
                    <p class="caption">
                      荷物を他のドライバーに引継ぐ場合は、以下ボタンから操作を行ってください。
                    </p>
                    <div class="buttonArea">
                      <Button
                        variant="unelevated"
                        on:click={handoverConfirmDialog.openDialog}
                        >他のドライバーに引継ぐ</Button
                      >
                    </div>
                  </div>
                </Content>
              </Paper>
            {/if}
          </div>
        {:else}
          <div style="min-height: 100px;" />
        {/if}
      {:else if deliveryPackage.statusText === "不" && !cannotBeDelivered}
        <!--
        IF 持ち戻り対象の荷物（不）の荷物の場合、かつ配達継続可能の場合
        -->
        {#if userContext.readOnly}
          <!--
          IF 読取専用なら、「配達を再試行」「紛失登録を継続する」の代わりに「配達リストへ戻る」を表示
          -->
          <Button
            variant="unelevated"
            on:click={() => {
              pageRouter.moveToList();
            }}>配達リストへ戻る</Button
          >
          <div style="min-height: 100px;" />
        {:else}
          <Button
            variant="unelevated"
            on:click={() => {
              // 荷物を「未」にして配達リストの一番下に移動、配達リスト画面へ遷移
              if (updateTarget) {
                updateTarget.statusText = "未";
                updateTarget.lostOrDamaged = null;
                updateTarget.searchingForPackage = false;

                const newShippingList =
                  deliveryListUtils.moveToEndByTrackingNumber(
                    shippingList,
                    updateTarget.trackingNumber,
                  );
                userContext.deliveryList = newShippingList;

                userContext.store();

                toast.info($_("message.retryCompleted"));
              }
              pageRouter.moveToList();
            }}>配達を再試行</Button
          >
          {#if deliveryPackage.searchingForPackage}
            <Button
              variant="unelevated"
              color="secondary"
              style="margin-top: 8px;"
              on:click={() => {
                confirmReasonDialog.openDialog("紛失");
              }}>紛失登録を続ける</Button
            >
          {/if}
          <Button
            variant="outlined"
            color="secondary"
            style="margin-top: 10px;"
            on:click={handoverConfirmDialog.openDialog}
            >他のドライバーに引継ぐ</Button
          >
          <div style="min-height: 100px;" />
        {/if}
      {:else if cannotBeDelivered || deliveryPackage.statusText === "済"}
        <!--
          IF 配達継続不可、または配達完了の場合
          -->
        <Button
          variant="unelevated"
          on:click={() => {
            pageRouter.moveToList();
          }}>配達リストへ戻る</Button
        >
        <div style="min-height: 100px;" />
      {/if}

      <!--
        セクション8. フローティングアクションボタン
        -->
      <section class="floatingActionButtonArea">
        <button class="backBtn" on:click={confirmDiscardAndGoBack}>戻る</button>
      </section>
    </div>
  </main>

  <Footer {confirmDiscardAndChangePage} />
</div>

<div class="subContentsWrapper">
  <!-- ヘルプ表示 -->
  <svelte:component this={helpBase} {helpContents} {clickConfirm} />

  <!-- 送り状のQRコードとの照合をスキップするか否かの確認ダイアログ -->
  <div class="qrCodeVerificationSkipConfirmDialog wideWidthMdcDialog">
    <ConfirmDialog
      bind:this={qrCodeVerificationSkipConfirmDialog}
      type={ConfirmDialogTypes.OK_CLOSE}
      mandatory={true}
      onDialogClosedHandler={(event) => {
        if (event.detail.action === "ok") {
          qrCodeVerificationCompleted = true;
        }
      }}
    >
      <svelte:fragment slot="title">送り状の確認</svelte:fragment>
      <svelte:fragment slot="content">
        <p class="explanation">
          下記の荷物情報が送り状の記載と同一であることを確認してください。
        </p>
        <div class="comparisonTable">
          <div class="row">
            <p class="th">送り状番号</p>
            <p class="td">
              {formatTrackingNumber(deliveryPackage.trackingNumber)}
            </p>
          </div>
          {#if !deliveryPackage.unacquired}
            <div class="row">
              <p class="th">ご住所</p>
              <p class="td">
                {addressUtils.trimPrefecture(deliveryPackage.receiverAddress1) +
                  (deliveryPackage.receiverAddress2
                    ? deliveryPackage.receiverAddress2
                    : "")}
              </p>
            </div>
            <div class="row">
              <p class="th">お名前</p>
              <p class="td">{deliveryPackage.receiverName}</p>
            </div>
          {/if}
        </div>
      </svelte:fragment>
      <svelte:fragment slot="okLabel">確認しました</svelte:fragment>
    </ConfirmDialog>
  </div>

  <!-- 引継ぎの確認ダイアログ -->
  <div class="handoverConfirmDialog">
    <ConfirmDialog
      bind:this={handoverConfirmDialog}
      mandatory={true}
      type={ConfirmDialogTypes.CLOSE}
    >
      <svelte:fragment slot="title">他のドライバーに引継ぐ</svelte:fragment>
      <svelte:fragment slot="content">
        <div class="handoverMemoArea">
          <p class="instructions">引継ぎの連絡事項</p>
          <Textfield textarea bind:value={handoverMemo} />
        </div>
        <p class="attention">
          引継ぐ荷物の数が多い場合は、引継ぎ登録を行わずに他のドライバーに引き渡すことも可能です。
        </p>
        <div class="buttonArea">
          <Button
            color="secondary"
            variant="unelevated"
            on:click={loadingProgress.wrapAsync(async () => {
              // 引継ぎアクションを実行するためのフラグを設定
              isHandoverAction = true;

              deliveryPackage.isRegisteredHandover = true;

              // 通信欄に引継ぎ登録を行った旨とExtraEventの状況、引継ぎの連絡事項を登録
              internalMessageOfHandover =
                "引継ぎ登録を実施" +
                "\n【登録日時】" +
                formatDate(new Date(), "yyyy-MM-dd HH:mm:ss", {
                  locale: localeJa,
                }) +
                "\n【登録者】" +
                userContext.loginUser.displayName +
                "\n";
              if (handoverMemo !== "") {
                internalMessageOfHandover +=
                  "【連絡事項】" + handoverMemo + "\n";
              }
              if (deliveryPackage.statusText === "不") {
                internalMessageOfHandover +=
                  "※" +
                  $_(
                    "pages.Update.extraEventTypeLabel." +
                      deliveryPackage.extraEvent[
                        deliveryPackage.extraEvent.length - 1
                      ].extraEventType,
                  ) +
                  "のため、配達不可登録済みの荷物です。";
              }

              // 引継ぎメモを登録する
              const updateResponse = await execStatusUpdateApi();
              await updateLocalStorage(updateResponse);

              // 引継ぎアクションのフラグを初期化
              isHandoverAction = false;

              // 未配達リストの一番下に移動し、配達リスト画面に戻る
              let handoverPackageIndex = shippingList.findIndex(
                (e) => e.trackingNumber === deliveryPackage.trackingNumber,
              );
              let handoverPackage = shippingList[handoverPackageIndex];
              shippingList.splice(handoverPackageIndex, 1);
              shippingList.push(handoverPackage);
              shippingList = shippingList;
              userContext.store();
              toast.info($_("message.handoverRegistrationCompleted"));
              pageRouter.moveToList();
            })}
          >
            <Label>引継ぎ登録を行う</Label>
          </Button>
        </div>
      </svelte:fragment>
    </ConfirmDialog>
  </div>

  <!-- 配達完了登録の最終確認ダイアログ -->
  <div class="deliveryCompletionConfirmDialog wideWidthMdcDialog">
    <ConfirmDialog
      bind:this={deliveryCompletionConfirmDialog}
      mandatory={true}
      type={ConfirmDialogTypes.OK_CLOSE}
      onDialogClosedHandler={(event) => {
        if (event.detail.action === "ok") {
          statusUpdate();
        }
      }}
    >
      <svelte:fragment slot="title">登録内容確認</svelte:fragment>
      <svelte:fragment slot="content">
        <div class="finalConfirmationArea">
          <table class="infoTable">
            <tr>
              <th>送り状番号</th>
              <td>
                {formatTrackingNumber(deliveryPackage.trackingNumber)}
                {#if deliveryPackage.numberOfPackages > 1}
                  <!-- 複数個口の場合 -->
                  <div>（複数口：{deliveryPackage.numberOfPackages}個）</div>
                {/if}
              </td>
            </tr>
            <tr>
              <th>ご住所</th>
              <td
                >{addressUtils.trimPrefecture(
                  deliveryPackage.receiverAddress1,
                ) +
                  (deliveryPackage.receiverAddress2
                    ? " " + deliveryPackage.receiverAddress2
                    : "")}</td
              >
            </tr>
            <tr>
              <th>お名前</th>
              <td>{deliveryPackage.receiverName}</td>
            </tr>
            <tr>
              <th>受渡方法</th>
              <td>
                {$_(`classes.deliveryMethod.${actualPackageDropPlace}`)}
                {#if Number.isInteger(deliveryPackage.cashOnDeliveryAmount) && !cannotBeDelivered}
                  (代引き: {deliveryPackage.cashOnDeliveryAmount.toLocaleString()}円)
                {/if}
              </td>
            </tr>
            {#if actualPackageDropPlace === DropOffLocation.LOCKER && lockerNumber}
              <tr>
                <th>Box番号</th>
                <td>{lockerNumber}</td>
              </tr>
            {/if}
            {#if actualPackageDropPlace === DropOffLocation.LOCKER && lockerPin}
              <tr>
                <th>暗証番号</th>
                <td>{lockerPin}</td>
              </tr>
            {/if}
          </table>
        </div>
        <div class="knowledgeRegistArea">
          <p class="instructions">配達メモ(共有事項)</p>
          <KnowledgeRegistration
            bind:editableByAddressKnowledge
            bind:changeBeforeByAddressKnowledge
            bind:editableNeighborhoodKnowledge
            bind:changeBeforeNeighborhoodKnowledge
          />
        </div>
      </svelte:fragment>
      <svelte:fragment slot="okLabel">配達を完了する</svelte:fragment>
    </ConfirmDialog>
  </div>

  <!-- 配達メモの登録ダイアログ -->
  <div class="knowledgeEditDialog">
    <ConfirmDialog
      bind:this={knowledgeEditDialog}
      mandatory={true}
      type={ConfirmDialogTypes.CLOSE}
    >
      <svelte:fragment slot="title">配達メモ(共有事項)の登録</svelte:fragment>
      <svelte:fragment slot="content">
        <KnowledgeRegistration
          bind:editableByAddressKnowledge
          bind:changeBeforeByAddressKnowledge
          bind:editableNeighborhoodKnowledge
          bind:changeBeforeNeighborhoodKnowledge
        />
        <div class="buttonArea">
          <Button
            color="secondary"
            variant="unelevated"
            disabled={editableByAddressKnowledge ===
              changeBeforeByAddressKnowledge.memo &&
              editableNeighborhoodKnowledge ===
                changeBeforeNeighborhoodKnowledge.memo}
            on:click={loadingProgress.wrapAsync(async () => {
              await registOrUpdateKnowledgeApi();
              await updateKnowledgeOfLocalStorage();

              knowledgeEditDialog.closeDialog();
            })}
          >
            <Label>メモを登録</Label>
          </Button>
        </div>
      </svelte:fragment>
      <svelte:fragment slot="okLabel">登録する</svelte:fragment>
    </ConfirmDialog>
  </div>

  <!--不在通知の再送信ダイアログ-->
  <AbsenceNotificationDialog
    bind:this={absentNotificationDialog}
    bind:extraEventType
    bind:editableByAddressKnowledge
    bind:changeBeforeByAddressKnowledge
    bind:editableNeighborhoodKnowledge
    bind:changeBeforeNeighborhoodKnowledge
    resendFlag={true}
  />

  <!-- 戻るダイアログ -->
  <ConfirmDialog
    bind:this={backConfirmDialog}
    mandatory={true}
    type={ConfirmDialogTypes.OK_CLOSE}
    onDialogClosedHandler={(event) => {
      if (event.detail.action === "ok") {
        functionAfterDiscard();
      }
    }}
  >
    <svelte:fragment slot="title">確認</svelte:fragment>
    <svelte:fragment slot="content"
      >入力した情報は破棄されます。<br />よろしいですか？</svelte:fragment
    >
  </ConfirmDialog>
</div>
<!-- ダイアログ -->
<div class="dialogs">
  <ConfirmReasonDialog
    bind:this={confirmReasonDialog}
    bind:extraEventType
    bind:paymentNotPossibleType
    bind:acceptDeniedReasonType
    bind:numberOfLost
    bind:lostRegistrationFlag
    bind:searchingForPackageFlag
    bind:editableByAddressKnowledge
    bind:changeBeforeByAddressKnowledge
    bind:editableNeighborhoodKnowledge
    bind:changeBeforeNeighborhoodKnowledge
  />
  <PersonalMemoDialog
    bind:this={personalMemoDialog}
    bind:trackingNumber={deliveryPackage.trackingNumber}
    on:update={updatePersonalMemo}
  />
</div>

<style lang="scss">
  .lateralMarginWrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 10px;
  }

  .border {
    width: 90%;
    margin: 20px auto;
    border-bottom: 1px solid #999;
  }

  .infoTable {
    width: 100%;

    tr {
      // 注意：:isではなくネストでth,tdを指定するとSafariで動作しない。
      // ネストの場合、trに対して何らかのスタイルを定義すると何故か動作する
      &:not(:last-of-type) :is(th, td) {
        border-bottom: 1px solid #eee;
      }
      &.em {
        td {
          font-weight: 500;
          color: var(--mdc-theme-error);
        }
      }
    }
    th,
    td {
      padding: 9px 2px;
      vertical-align: middle;
      font-size: 16px;
    }
    th {
      text-wrap: nowrap;
      font-weight: 600;
    }
    td {
      position: relative;
      padding-left: 6px;
      text-align: left;
      word-break: break-all;

      .callButton,
      .memoButton {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        padding: 1px 4px;
        background-color: #fff;
        border: 1px solid #018786;
        border-radius: 4px;

        span {
          font-size: 10px;
          color: #018786;

          &.material-icons {
            font-size: 14px;
            vertical-align: middle;
          }
        }
      }
    }
    td.trackingNumberCell {
      padding-right: 70px;

      .multiple {
        color: #b71c1c;
        margin-top: 5px;
      }
    }
    td.nameCell {
      padding-right: 90px;
    }
    .highlight {
      padding: 3px;
      border-radius: 4px;
      background-color: #facfcf;
      line-height: 1.2;
    }
    .note {
      font-size: 11.4px;
      color: #c80000;
    }
  }

  .damageCheck {
    text-align: right;
  }

  /*
   * セクション1. 配達基本情報
   */
  .deliveryInfoArea {
    margin-top: 14px;
    padding: 2px 6px;
    background-color: #fff;
    border-radius: 10px;
  }

  .cautionOfCashOnDelivery {
    background-color: #ffe7e7;
    border-radius: 10px;
    color: #f00;
    font-size: 16px;
    line-height: 1.4;
    margin-top: 7px;
    padding: 0.5em 1em;

    em {
      font-style: normal;
      font-weight: bold;
    }
  }

  .cautionOfReturn {
    background-color: #ffe7e7;
    border-radius: 10px;
    color: #f00;
    font-size: 16px;
    line-height: 22px;
    margin-top: 7px;
    padding: 0.5em 1em;

    .returnReason {
      background-color: #eb5656;
      color: rgb(255, 255, 255);
      padding: 2px 4px;
      margin-right: 4px;
      border-radius: 3px;
    }
  }

  /*
   * セクション2. 配達履歴
   */
  .deliveryHistoryArea {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 7px;
    padding: 15px 10px;
    text-align: left;

    .deliveryHistoryAreaTitle {
      font-size: 16px;
      font-weight: bold;
    }

    .deliveryHistoryContents {
      margin-top: 10px;

      .deliveryHistoryTable {
        margin: 0 5px;

        .row {
          display: flex;
          align-items: baseline;
          margin-top: 10px;

          .dateAndTime {
            color: #018786;
            font-size: 0.8em;
            font-weight: bold;
            width: 95px;
          }

          .history {
            font-size: 0.9em;
            width: calc(100% - 90px);
          }
        }
      }
    }
  }

  /*
   * セクション3. 個人メモ
   */
  .personalMemoArea {
    background-color: #fff;
    border-radius: 10px;
    margin-top: 7px;
    padding: 15px 10px 10px;
    text-align: left;

    .personalMemoAreaTitle {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .personalMemoAreaContent {
      font-size: 0.9em;
      line-height: 1.4;
      padding: 2px 5px 0;
    }
  }

  /*
   * セクション4. ナレッジ
   */
  .knowledgeArea {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    padding: 15px 10px;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;

    .knowledgeAreaHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .knowledgeAreaTitle {
        margin-left: 4px;
        font-size: 16px;
        font-weight: bold;
      }
      .editButton {
        padding: 1px 4px;
        background-color: #fff;
        border: 1px solid #018786;
        border-radius: 4px;

        span {
          font-size: 10px;
          color: #018786;

          &.material-icons {
            font-size: 14px;
            vertical-align: middle;
          }
        }
      }
    }

    .knowledgeGroup {
      margin-top: 5px;
      padding: 5px 7px;
      border: 1px solid #ddd;
      border-radius: 3px;
      line-height: 1.3;

      .knowledgeMessage {
        font-size: 15px;
        line-height: 1.4;
      }

      .knowledgeInfo {
        margin-top: 3px;
        font-size: 12px;
        color: #00000080;
        line-height: 15px;
      }

      .knowledgeIcon {
        font-size: 17px;
        vertical-align: middle;
        margin-bottom: 2px;
        margin-right: 2px;
      }

      .nameHasChanged {
        color: #f00;
        font-weight: bold;
      }

      .border {
        border-bottom: 1px solid #ddd;
        width: 100%;
        margin: 5px 0;
      }
    }
  }
  .knowledgeRegistButtonArea {
    margin-top: 15px;
  }

  /*
   * セクション5. 連絡事項
   */

  .communicationMessageArea {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    gap: 10px;
    padding: 15px 10px;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;

    .communicationMessageAreaTitle {
      margin-left: 4px;
      font-size: 16px;
      font-weight: bold;
    }

    .message {
      margin-top: 5px;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 3px;
      line-height: 1.3;
      .text {
        font-size: 15px;
      }
    }
  }

  .changeCalculationArea {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    gap: 10px;
    padding: 15px 10px;
    text-align: left;
    background-color: #fff;
    border-radius: 10px;
  }

  .resendAbsenceNoticeArea {
    margin: 20px 20px 0;

    p {
      margin-bottom: 5px;
    }

    :global(.mdc-button) {
      width: 100%;
    }
  }

  .readonlyNoticeArea {
    background-color: #f1e1dd;
    padding: 15px;
    border-radius: 10px;

    .message {
      color: #c62800;
      font-size: 15px;
      font-weight: 600;
      text-align: left;
    }

    .releaseReadOnlyButton {
      margin: 10px 5px;
      padding: 6px 16px;
      font-size: 16px;
      color: #fff;
      background-color: #c62800;
      border: none;
      border-radius: 6px;
      width: calc(100% - 10px);
    }
  }

  /*
   * セクション6. QRコードスキャンによる送り状との整合性チェック
   */
  .qrCodeVerificationArea {
    background-color: #f1e1dd;
    padding: 15px;
    border-radius: 10px;

    .message {
      color: #c62800;
      font-size: 15px;
      font-weight: 600;
      text-align: left;
    }

    .qrButton {
      margin: 10px 5px;
      padding: 6px 16px;
      font-size: 16px;
      color: #fff;
      background-color: #c62800;
      border: none;
      border-radius: 6px;
      width: calc(100% - 10px);
    }

    .otherButtonArea {
      text-align: right;
    }

    .otherButton {
      padding: 0;
      font-size: 14px;
      color: #c62800;
      background-color: #f1e1dd;
      border: none;
      text-decoration: underline;
    }
  }

  .qrCodeVerificationCompletedArea {
    display: flex;
    gap: 10px;
    background-color: #fff;
    padding: 16px 10px;
    border-radius: 10px;

    .material-icons {
      color: var(--mdc-theme-primary);
    }

    .message {
      font-size: 14px;
      font-weight: bold;
      text-align: left;
    }
  }

  /*
   * セクション7. 配達完了登録／通常の配達不可登録
   */
  .shippingAreaWrapper {
    margin-bottom: 100px;

    :global(.mdc-tab) {
      padding: 0 16px;
    }

    :global(.smui-paper) {
      padding: 0;
    }
  }

  .shippingArea {
    background-color: #fff;
    border-radius: 10px;
    padding: 15px 0;

    .warningText {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      background-color: #e7f4f4;
      color: #018786;
      border-radius: 4px;
      line-height: 1.4;
      margin: 4px 16px;
      padding: 0.5em 1em;
    }

    :global(.border) {
      margin: 10px auto;
    }

    :global(.registerButton) {
      min-width: 170px;
      height: 50px;
      margin-top: 10px;
      color: #fff;
    }

    .deliveryNoticeCaution {
      background-color: #fae6e6;
      border-radius: 2px;
      color: #333;
      font-size: 0.875rem;
      line-height: 1.6;
      margin-top: 10px;
      padding: 8px 10px;
      text-align: left;
    }

    .deliveryNoticeCaution em {
      font-weight: bold;
    }

    .deliveryNoticeTable {
      border-top: 1px solid #333;
      border-left: 1px solid #333;
      margin-top: 10px;
      width: 100%;
    }

    .deliveryNoticeTable th {
      background-color: #f7c4c4;
      border-right: 1px solid #333;
      border-bottom: 1px solid #333;
      min-width: 80px;
      padding: 5px 8px;
    }
    .deliveryNoticeTable td {
      background-color: #ffebeb;
      border-right: 1px solid #333;
      border-bottom: 1px solid #333;
      padding: 5px 8px;
    }
  }

  .handoverActionArea {
    .caption {
      font-size: 14px;
      text-align: left;
      line-height: 1.2;
    }

    .buttonArea {
      margin-top: 30px;

      :global(.mdc-button) {
        background-color: #018786;
        border: none;
        border-radius: 10px;
        display: inline-block;
        font-size: 16px;
        height: 50px;
        padding: 14px 30px;
      }
    }
  }

  /*
   * セクション8. フローティングアクションボタン
   */
  .floatingActionButtonArea {
    button {
      width: 60px;
      height: 60px;
      border: none;
      border-radius: 50%;
      font-weight: bold;
      color: #fff;
    }

    .backBtn {
      position: fixed;
      font-size: 16px;
      bottom: calc(70px + var(--app-inset-bottom-padding));
      left: 25px;
      padding: 0;
      margin: 0;
      background-color: #018786;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
    }
  }

  /*
   * 非メインコンテンツ
   */

  // 送り状の確認ダイアログ（QRコードスキャンのスキップ時）
  .qrCodeVerificationSkipConfirmDialog {
    .explanation {
      line-height: 1.4;
    }

    .comparisonTable {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-top: 6px;
      font-size: 15px;

      .row {
        display: flex;
        gap: 6px;
        border-bottom: 1px solid #eee;

        > p {
          display: flex;
          align-items: center;
          line-height: 1.4;
          word-break: break-all;
          color: #333;
        }
      }

      .th {
        justify-content: center;
        min-width: 94px;
        padding: 4px 0;
        font-weight: 600;
      }

      .td {
        flex-grow: 1;
      }
    }
  }

  // 引継ぎの確認ダイアログ
  .handoverConfirmDialog {
    color: #333;

    :global(.mdc-dialog__header) {
      text-align: center;
    }

    :global(.mdc-dialog .mdc-dialog__content) {
      color: #333;
    }

    .handoverMemoArea {
      margin-top: 15px;

      :global(.mdc-text-field--textarea) {
        width: 100%;
      }
    }

    .instructions {
      position: relative;
      padding-left: 16px;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .instructions::before {
      content: "■";
      position: absolute;
      left: 0;
      color: #018786;
      top: 0;
      transform: translateY(0);
    }

    .attention {
      background-color: #fae6e6;
      border-radius: 2px;
      color: #333;
      font-size: 14px;
      margin-top: 20px;
      padding: 8px;
    }

    .buttonArea {
      margin-top: 15px;

      :global(.mdc-button) {
        width: 100%;
      }
    }
  }

  // 登録内容確認ダイアログ（配完時）
  .deliveryCompletionConfirmDialog {
    :global(.mdc-dialog .mdc-dialog__content) {
      padding: 0 20px 17px;
    }

    .finalConfirmationArea {
      color: #333;
    }

    .infoTable {
      th,
      td {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 15px;
      }
      td {
        line-height: 1.4;
      }
    }
    .knowledgeRegistArea {
      margin-top: 10px;

      .instructions {
        position: relative;
        padding-left: 16px;
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }
      .instructions::before {
        content: "■";
        position: absolute;
        left: 0;
        color: #018786;
        top: 0;
        transform: translateY(0);
      }
    }

    :global(.mdc-dialog__actions) {
      min-height: 70px;

      :global(.mdc-button) {
        background-color: #1976d2;
        color: white;
        width: 50%;
        height: 45px;
        margin-right: 10px;
      }
    }
  }

  // 配達メモの登録・編集ダイアログ
  .knowledgeEditDialog {
    color: #333;

    :global(.mdc-dialog .mdc-dialog__surface) {
      max-width: calc(100vw - 16px);
    }
    :global(.mdc-dialog .mdc-dialog__content) {
      display: block;
      padding: 0 20px 17px;
    }
    .buttonArea {
      display: flex;
      flex-flow: column;
      gap: 10px;
      margin-top: 15px;

      :global(.mdc-button) {
        width: 100%;
      }
    }
  }
</style>
