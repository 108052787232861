<script>
  import { Icon } from "@smui/button";
  import { getContext, onDestroy } from "svelte";

  import { initAudioContext } from "~/libs/audio";
  import { CONTEXT_KEY_USER, QrHomeTypes } from "~/libs/constants";
  import pageRouter from "~/libs/pageRouter";
  import { appPageStore, readonly } from "~/libs/stores";
  import List from "~/pages/List.svelte";
  import OtherMenu from "~/pages/OtherMenu.svelte";
  import QrHome from "~/pages/QrHome.svelte";

  /** @type {import("~/libs/commonTypes").UserContext} */
  const userContext = getContext(CONTEXT_KEY_USER);

  /** @type {Function} */
  export let confirmDiscardAndChangePage = null;

  /** 現在のAppPage @type {import("~/libs/commonTypes").AppPageStore} */
  let currentAppPage;
  /** AppPageStoreのUnsubscriber @type {import("svelte/store").Unsubscriber} */
  const appPageStoreUnsubscriber = appPageStore.subscribe((appPage) => {
    if (appPage && currentAppPage !== appPage) {
      currentAppPage = appPage;
    }
  });

  onDestroy(() => {
    appPageStoreUnsubscriber?.();
  });
</script>

<footer>
  {#if userContext.hasDriverRole()}
    <div class="column" class:active={currentAppPage?.name == List.name}>
      <button
        on:click={() => {
          if (confirmDiscardAndChangePage) {
            confirmDiscardAndChangePage(() => {
              pageRouter.moveToList();
            });
            return;
          }
          pageRouter.moveToList();
        }}
      >
        <Icon class="material-icons md-dark">format_list_numbered</Icon><br />
        <span class="label">配達リスト</span>
      </button>
    </div>

    <div
      class="column"
      class:active={currentAppPage?.name == QrHome.name &&
        currentAppPage?.props?.qrHomeType == QrHomeTypes.OUT_FOR_DELIVERY}
      class:inactive={$readonly}
    >
      <button
        on:click={() => {
          if (confirmDiscardAndChangePage) {
            confirmDiscardAndChangePage(() => {
              initAudioContext();
              pageRouter.moveToQrHome(QrHomeTypes.OUT_FOR_DELIVERY);
            });
            return;
          }
          initAudioContext();
          pageRouter.moveToQrHome(QrHomeTypes.OUT_FOR_DELIVERY);
        }}
        disabled={$readonly}
      >
        <Icon class="material-icons md-dark">local_shipping</Icon><br />
        <span class="label">持ち出し</span>
      </button>
    </div>

    <div
      class="column"
      class:active={currentAppPage?.name == QrHome.name &&
        currentAppPage?.props?.qrHomeType ==
          QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT}
      class:inactive={$readonly}
    >
      <button
        on:click={() => {
          if (confirmDiscardAndChangePage) {
            confirmDiscardAndChangePage(() => {
              initAudioContext();
              pageRouter.moveToQrHome(QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT);
            });
            return;
          }
          initAudioContext();
          pageRouter.moveToQrHome(QrHomeTypes.TAKEBACK_PACKAGE_TO_DEPOT);
        }}
        disabled={$readonly}
      >
        <Icon class="material-icons md-dark">restart_alt</Icon><br />
        <span class="label">持ち戻り･返品</span>
      </button>
    </div>
  {/if}

  {#if userContext.hasContractDriverRole()}
    <div
      class="column"
      class:active={currentAppPage?.name == QrHome.name &&
        currentAppPage?.props?.qrHomeType == QrHomeTypes.PICKUP_AND_SORT}
    >
      <button
        on:click={() => {
          if (confirmDiscardAndChangePage) {
            confirmDiscardAndChangePage(() => {
              initAudioContext();
              pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
            });
            return;
          }
          initAudioContext();
          pageRouter.moveToQrHome(QrHomeTypes.PICKUP_AND_SORT);
        }}
      >
        <Icon class="material-icons md-dark">call_split</Icon><br />
        <span class="label">荷受け・輸送</span>
      </button>
    </div>

    <div
      class="column"
      class:active={currentAppPage?.name == QrHome.name &&
        currentAppPage?.props?.qrHomeType == QrHomeTypes.RETURN_TO_EC}
    >
      <button
        on:click={() => {
          if (confirmDiscardAndChangePage) {
            confirmDiscardAndChangePage(() => {
              initAudioContext();
              pageRouter.moveToQrHome(QrHomeTypes.RETURN_TO_EC);
            });
            return;
          }
          initAudioContext();
          pageRouter.moveToQrHome(QrHomeTypes.RETURN_TO_EC);
        }}
      >
        <Icon class="material-icons md-dark">cached</Icon><br />
        <span class="label">返品集荷・引渡</span>
      </button>
    </div>
  {/if}

  <div class="column" class:active={currentAppPage?.name == OtherMenu.name}>
    <button
      on:click={() => {
        if (confirmDiscardAndChangePage) {
          confirmDiscardAndChangePage(() => {
            pageRouter.moveToOtherMenu();
          });
          return;
        }
        pageRouter.moveToOtherMenu();
      }}
    >
      <Icon class="material-icons md-dark">menu</Icon><br />
      <span class="label">メニュー</span>
    </button>
  </div>
</footer>

<style lang="scss">
  footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 55px;
    min-height: 55px;
    padding-bottom: calc(env(safe-area-inset-bottom, 10px) - 10px);
    border-top: solid 0.5px #b2b2b2;
    background-color: #fff;
  }

  .column {
    button {
      border: none;
      background: none;
      text-align: center;
      line-height: 1;

      :global(.material-icons) {
        font-size: 28px;
        color: var(--mdc-theme-secondary);
      }

      .label {
        font-size: 11px;
        color: var(--mdc-theme-secondary);
      }
    }

    &.active button {
      pointer-events: none;

      :global(.material-icons),
      .label {
        color: var(--mdc-theme-primary);
      }
    }

    &.inactive button {
      pointer-events: none;

      :global(.material-icons),
      .label {
        color: #b2b2b2;
      }
    }
  }
</style>
